export const secondToHHMMSS = (second: number) => {
    if (second && Number.isInteger(second)) {
      const new_date = new Date(second * 1000);
      return new_date.toISOString().slice(11, 19);
    }
  
    return 0;
  };
  
  export const chargerDecoder = (code: number) => {
    let bit_10 = 0;
    let bit_9 = 0;
    let bit_8 = 0;
    let bit_7 = 0;
    let bit_6 = 0;
    let bit_5 = 0;
    let bit_4 = 0;
    let bit_3 = 0;
    let bit_2 = 0;
    let bit_1 = 0;
    let bit_0 = 0;

    bit_0 = code % 2
    code = (code-bit_0)/2

    bit_1 = code % 2
    code = (code-bit_1)/2

    bit_2 = code % 2
    code = (code-bit_2)/2

    bit_3 = code % 2
    code = (code-bit_3)/2

    bit_4 = code % 2
    code = (code-bit_4)/2

    bit_5 = code % 2
    code = (code-bit_5)/2

    bit_6 = code % 2
    code = (code-bit_6)/2

    bit_7 = code % 2
    code = (code-bit_7)/2

    bit_8 = code % 2
    code = (code-bit_8)/2

    bit_9 = code % 2
    code = (code-bit_9)/2

    bit_10 = code % 2
    code = (code-bit_10)/2

    
    if (bit_10) {
      return "Self Diagnostic";
    } else if (bit_3) {
      return "In Use";
    } else if (bit_2) {
      return "Occupied";
    } else if (bit_1) {
      return "Faulted";
    } else if (bit_0) {
      return "Ready";
    } else {
      // Reduced Power, power but ready
      return "OFF";
    }
  };
  