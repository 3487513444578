import { configureStore } from "@reduxjs/toolkit";

import costProfileReducer from "./costProfile";
import systemReducer from "./system";
import sidebarReducer from "./sidebar";
import socketIoReducer from "./socket-io";
import userReducer from "./user";

export { setCostProfiles, setActiveCostProfile } from "./costProfile";
export { setSideBarInfo, useSideBarInfo } from "./sidebar";
export { setSystemState, useSystemState } from "./system";
export { setUser, useUser } from "./user";

export const store = configureStore({
  reducer: {
    costProfiles: costProfileReducer,
    system: systemReducer,
    sidebar: sidebarReducer,
    socketIo: socketIoReducer,
    user: userReducer,
  }
});

export default store;
