import React from "react";
import ReactDom from "react-dom";
import classes from "./pcModal.module.css";

export default function EmsModal(props) {
  if (!props.open) return null;

  return ReactDom.createPortal(
    <div>
      <div className={classes.background} />
      <div
        className={classes.main}
        style={{
          width: `${props.modal_width}`,
          height: `${props.modal_height}`,
        }}
      >
        {props.children}
      </div>
    </div>,
    document.getElementById("portal")
  );
}
