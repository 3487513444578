import React, { useMemo, useState } from 'react'
import styles from "./revenue.module.scss";
import { G2, Pie } from "@ant-design/plots";
import { createRecoil, round, useFilteredSystems, useMetrics, useSessionSummary, useSystems, useTimeOfDay, useTimeRange, useUser } from "@ecamion/sucrose"
import { getDateWithFixedHours, getUnixTimestamp } from '@utils/common';
import DatePicker from 'react-date-picker';
const Revenue = (props :any) => {
    const antdTheme = {
        colors10: [
            "#FFB6BB",
            '#FF9BA1',
            '#FE7A82',
            '#DD6A72',
            '#BA5960',
            '#96484D',
            '#72373B',
            '#4F2629',
        ].reverse(),
        colors20: [
            "#FFB6BB",
            '#FF9BA1',
            '#FE7A82',
            '#DD6A72',
            '#BA5960',
            '#96484D',
            '#72373B',
            '#4F2629',
        ].reverse(),
    }
    const [tab, setTab] = useState("system");
    
    const [payment] = useTimeOfDay({
        data: "payment",
        systemIds: props.systemsIds,
        start: getUnixTimestamp(getDateWithFixedHours(props.startDate,true)),
        end: getUnixTimestamp(getDateWithFixedHours(props.endDate))
    })
    const systems = useSystems()
    
    const G = G2.getEngine('canvas');
    const [bySystem, byOwnership]: any = useMemo(() => {
        const system: any = {}
        const ownership: any = {}
        payment.data?.forEach((d: any) => {
            if (!system[d.system_id]) {
                system[d.system_id] = {
                    value: 0,
                    type: systems[d.system_id]?.siteName
                }
            }
            const o = systems[d.system_id]?.ownership
            if (o) {
                if (!ownership[o]) {
                    ownership[o] = {
                        value: 0,
                        type: o
                    }
                }
                ownership[o].value += Number(d.payment)
            }
            system[d.system_id].value += Number(d.payment)
        })

        const base = {
            appendPadding: 10,
            angleField: 'value',
            colorField: 'type',
            radius: 0.75,
            legend: false,
            autoFit: true,
            label: {
                type: 'spider',
                labelHeight: 40,
                formatter: (data: any, mappingData: any) => {
                    const group = new G.Group({});
                    group.addShape({
                        type: 'circle',
                        attrs: {
                            x: 0,
                            y: 0,
                            width: 40,
                            height: 50,
                            r: 5,
                            fill: mappingData.color,
                        },
                    });
                    group.addShape({
                        type: 'text',
                        attrs: {
                            x: 10,
                            y: 8,
                            text: `${data.type}`,
                            fill: mappingData.color,
                        },
                    });
                    group.addShape({
                        type: 'text',
                        attrs: {
                            x: 0,
                            y: 25,
                            text: `$${data.value} ${round(data.percent * 100, 2)}%`,
                            fill: 'rgba(0, 0, 0, 0.65)',
                            fontWeight: 600,
                        },
                    });
                    return group;
                },
            },
            theme: antdTheme,
            interactions: [
                {
                    type: 'element-selected',
                },
                {
                    type: 'element-active',
                },
            ],
        }
        return [{
            ...base, data: Object.values(system).map((d: any) => {
                return {
                    ...d,
                    value: round(d.value / 100, 1)
                }
            })
        }, {
            ...base, data: Object.values(ownership).map((d: any) => {
                return {
                    ...d,
                    value: round(d.value / 100, 1)
                }
            })
        }]
    }, [payment.data, Object.keys(systems).length])

    return (
        <div className={styles.revenue_container}>
        <div className={styles.revenue_header}>
            <div className={styles.revenue_header_left}> Revenue Share</div>
            <div className={styles.revenue_header_right}>
                <div style={{ marginRight: "5%", zIndex: 9999 }}>
                    <DatePicker
                    onChange={(value: any) => {
                        props.setStartDate(value);
                    }}
                    value={props.startDate}
                    clearIcon={null}
                    />
                </div>
                <div style={{ marginRight: "5%", zIndex: 9999 }}>
                    <DatePicker
                    onChange={(value: any) => {
                        props.setEndDate(value);
                    }}
                    value={props.endDate}
                    clearIcon={null}
                    />
                </div>
                <div className={ tab === "system" ? styles.button_filter_active : styles.button_filter }
                    onClick={() => {setTab("system");}}>
                        By system
                </div>
                <div className={ tab === "ownership" ? styles.button_filter_active : styles.button_filter }
                    onClick={() => {setTab("ownership");}}>
                        By ownership
                </div>
            </div>
        </div>
        <div className={styles.revenue_body}>
            {
                tab === "system" ? <Pie {...bySystem} /> :
                    <Pie {...byOwnership} />
            }
        </div>
      </div>
    )
}

export default Revenue