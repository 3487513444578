import { IconContext } from "react-icons/lib";
import styles from "./subCard.module.scss";

export default function SubCard({
  title,
  titleColor,
  icon,
  fields = null,
  iconSize = "24",
}: {
  title: string;
  titleColor?: string;
  icon: any;
  fields?: any;
  iconSize?: string;
}) {
  const style: any = {};
  if (titleColor) {
    style.color = titleColor;
  }
  return (
    <div className={styles.root}>
      <IconContext.Provider
        value={{ className: "shared-class", size: iconSize }}
      >
        <>{icon}</>
      </IconContext.Provider>
      <div className={styles.right}>
        <div className={styles.title} style={style}>
          {title}
        </div>
        {fields ? (
          Object.keys(fields).map((key: any) => {
            return (
              <div key={key} className={styles.field}>
                {key}
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
