import React, { FunctionComponent, ReactNode } from "react";
import { Button, Text } from "@nextui-org/react";

type CustomButtonType = "black" | "transparent" | "error";

const ButtonSpec: any = {
  black: {
    color: "#4f5153",
    bold: true,
    backgroundColor: "#f2f2f2",
    size: "md",
  },
  transparent: {
    color: "black",
    bold: true,
    backgroundColor: "rgba(0,0,0,0)",
    size: "md",
  },
  error: {
    color: "white",
    bold: true,
    backgroundColor: "#F31260",
    size: "md",
  },
};

const CustomButton: FunctionComponent<CustomButtonProps> = (props) => {
  const spec = ButtonSpec[props.cbuttonType || "black"];
  const css: any = {
    backgroundColor: spec.backgroundColor,
    borderRadius: "6px",
    boxShadow: "5px 5px 5px #c8c7c7, -3px -3px 3px white",
    fontFamily: "open sans, sans-serif"
  };
  if (props.fullWidth) {
    css.width = "100%";
  }
  return (
    <Button {...props} size={spec.size} css={css}>
      {typeof props.children === "string" ? (
        <Text b={props.bold ?? spec.bold} color={spec.color}>
          {props.children}
        </Text>
      ) : (
        props.children
      )}
    </Button>
  );
};

interface CustomButtonProps {
  cbuttonType?: CustomButtonType;
  css?: any;
  bold?: boolean;
  fullWidth?: boolean;
  children?: ReactNode;
  auto?: boolean;
  onClick: () => void;
  disabled?: boolean;
  icon?: any;
}

export default CustomButton;
