import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Text } from "@nextui-org/react";
import CustomButton from "@pages/pricingConfig/components/customButton";

const ConfirmModal: FunctionComponent<ConfirmModalProps> = (props) => {
  const { t: translation } = useTranslation();
  const closeHandler = () => {
    props.setVisible(false);
  };
  return (
    <Modal
      closeButton
      aria-labelledby="modal-title"
      open={props.visible}
      onClose={closeHandler}
      width="500px"
    >
      <Modal.Header>
        <Text b id="modal-title" size={20} css={{ color: "#414141" }}>
          {props.title}
        </Text>
      </Modal.Header>
      <Modal.Body>{props.content}</Modal.Body>
      <Modal.Footer>
        <CustomButton cbuttonType={"transparent"} auto onClick={closeHandler}>
        {translation("pricingConfig.cancelButton")}
        </CustomButton>
        <CustomButton
          auto
          onClick={async () => {
            await props.onConfirm();
            closeHandler();
          }}
        >
          {translation("pricingConfig.confirmButton")}
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

interface ConfirmModalProps {
  visible: boolean;
  setVisible: Function;
  title: string;
  content: any;
  onConfirm: Function;
}

export default ConfirmModal;