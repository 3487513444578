import { useState, FunctionComponent } from "react";

import BatteryModule from "./components/batteryModule";
import HeadBar from "@components/headBar";
import styles from "./cellTemperature.module.scss";
import { useFieldContext, StringModules } from "@services";

const CellTemperature: FunctionComponent = () => {
  const headBarTitle = "Cell Temperature";
  const [selected, setSelected] = useState(1);
  const fields = useFieldContext();
  const modules = [1, 2, 3].map((string) => {
    return Array(16)
      .fill(0)
      .map((_, module) => {
        return fields[
          `String${string}Module${module + 1}` as keyof StringModules
        ];
      });
  });

  return (
    <div className={styles.root_container}>
      <HeadBar title={headBarTitle} />
      <div className={styles.main_container}>
        <div className={styles.buttonlist_con}>
          <div
            className={styles.button_con}
            onClick={() => {
              setSelected(1);
            }}
            style={{ backgroundColor: `${selected === 1 ? "#3376bb" : ""}` }}
          >
            String 1
          </div>
          <div
            className={styles.button_con}
            onClick={() => {
              setSelected(2);
            }}
            style={{ backgroundColor: `${selected === 2 ? "#3376bb" : ""}` }}
          >
            String 2
          </div>
          <div
            className={styles.button_con}
            onClick={() => {
              setSelected(3);
            }}
            style={{ backgroundColor: `${selected === 3 ? "#3376bb" : ""}` }}
          >
            String 3
          </div>
        </div>

        <div className={styles.scrollCon}>
          <div style={{ width: "100%", height: "100%" }}>
            {[1, 2, 3, 4].map((item, rowIndex) => {
              return (
                <div key={rowIndex} className={styles.batterymodule_row}>
                  {[1, 2, 3, 4].map((item, colIndex) => {
                    const moduleID: number = rowIndex * 4 + colIndex + 1;
                    const module = modules[selected - 1][moduleID - 1];
                    return (
                      <div key={moduleID} className={styles.batterymodule_con}>
                        {module && (
                          <BatteryModule
                            key={moduleID}
                            moduleID={moduleID}
                            data={module}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CellTemperature;
