import EmsModal from "@components/modal/emsModal";
import { getIconFromAlarmCode } from "@utils/activity";
import { getDateTime, getMinutesFromSeconds } from "@utils/common";
import { Fragment, useState } from "react";
import styles from "./event-modal.module.scss";
import { AlarmType, EventType, Signal1Type, Signal2Type, SignalType } from "./types";
import { getIcon, getSeverityColor } from "./event";

export interface EventModalProps {
  show: number;
  setShow: React.Dispatch<React.SetStateAction<number>>;
  event: EventType;
}

export interface AlertModalProps {
  show: number;
  setShow: React.Dispatch<React.SetStateAction<number>>;
  alarm: AlarmType;
}

export interface SignalModalProps {
  show: number;
  setShow: React.Dispatch<React.SetStateAction<number>>;
  signal: SignalType;
}

export const useEventModal = () => {
  const [show, setShow] = useState(0);
  const EventModalComponent = ({
    event,
  }: {
    event: EventType | null;
  }) => {
    return event && <EventModal show={show} setShow={setShow} event={event} />;
  };
  return {
    setShow,
    EventModalComponent,
  };
};

export const EventModal = ({ show, setShow, event }: EventModalProps) => {
  const isEventAlarm = Object.keys(event).includes("code");
  return isEventAlarm ? (
    <AlertModal show={show} setShow={setShow} alarm={event as AlarmType} />
  ) : (
    <SignalModal show={show} setShow={setShow} signal={event as SignalType} />
  );
};

export const AlertModal = ({ show, setShow, alarm }: AlertModalProps) => {
  const { code, type, summary, severity, severityText, action, actionText } =
    alarm;
  return (
    <EmsModal open={show} modal_width={"50%"} modal_height={"auto"}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.icon}>
            {getIcon(type)}
          </div>
          <div className={styles.summary} style={{color: getSeverityColor(type)}}>
            {`${type || "--"} - ${summary || "--"}`}
          </div>
        </div>
        <div className={styles.severity}>
          {`Severity Level: ${severity || -1}`}
        </div>
        <div className={styles["severity-text"]}>{severityText || "--"}</div>
        <div className={styles.action}>
          {`Action Required: ${action || -1}`}
        </div>
        <div className={styles["action-text"]}>{actionText || "--"}</div>
        <div
          className={styles.button}
          onClick={() => {
            setShow(0);
          }}
        >
          ok
        </div>
      </div>
    </EmsModal>
  );
};

export const SignalModal = ({ show, setShow, signal }: SignalModalProps) => {
  const { type, time, params } = signal;
  return (
    <EmsModal open={show} modal_width={"50%"} modal_height={"auto"}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.icon}>
            {getIcon("signal" + type)}
          </div>
          <div className={styles.summary} style={{color: getSeverityColor("signal")}}>{`G/A Signal ${type || ""}`}</div>
        </div>
        <div className={styles.issued}>
          <span className={styles["comp-title"]}>Issued Time:</span>
          <span className={styles["comp-content"]}>
            {time ? getDateTime(time) : "--"}
          </span>
        </div>
        <SignalFragment type={type} params={params} />
        <div
          className={styles.button}
          onClick={() => {
            setShow(0);
          }}
        >
          ok
        </div>
      </div>
    </EmsModal>
  );
};

const SignalFragment = ({
  type,
  params,
}: {
  type: number;
  params: Signal1Type | Signal2Type;
}) => {
  if (type === 1) return <Signal1Fragment {...(params as Signal1Type)} />;
  else if (type === 2) return <Signal2Fragment {...(params as Signal2Type)} />;
  return (
    <Fragment>
      <div style={{ marginBottom: "3%", marginLeft: "17%" }}>
        Unknown Signal
      </div>
    </Fragment>
  );
};

export const Signal1Fragment = ({
  battery_ready_time,
  reset_time,
}: Signal1Type) => {
  return (
    <Fragment>
      <div className={styles["battery-ready"]}>
        <span className={styles["comp-title"]}>Battery Ready Time:</span>
        <span className={styles["comp-content"]}>
          {getDateTime(battery_ready_time) || "--"}
        </span>
      </div>
      <div className={styles["reset-time"]}>
        <span className={styles["comp-title"]}>Signal 1 Reset Time:</span>
        <span className={styles["comp-content"]}>
          {getDateTime(reset_time) || "--"}
        </span>
      </div>
    </Fragment>
  );
};
export const Signal2Fragment = ({
  start_time,
  discharge_duration,
  percent_ess_ga,
  boost_enable,
  time_of_recharge,
  recharge_power_level,
}: Signal2Type) => {
  return (
    <Fragment>
      <div className={styles["start-time"]}>
        <span className={styles["comp-title"]}>Start Time:</span>
        <span className={styles["comp-content"]}>
          {getDateTime(start_time) || "--"}
        </span>
      </div>
      <div className={styles["discharge"]}>
        <span className={styles["comp-title"]}>Discharge Duration (mins):</span>
        <span className={styles["comp-content"]}>
          {getMinutesFromSeconds(discharge_duration) || "--"}
        </span>
      </div>
      <div className={styles["recharge"]}>
        <span className={styles["comp-title"]}>Time of Recharge:</span>
        <span className={styles["comp-content"]}>
          {`${time_of_recharge || "--"} min`}
        </span>
      </div>
      <div className={styles["pctess"]}>
        <span className={styles["comp-title"]}>Percent ESS:</span>
        <span className={styles["comp-content"]}>
          {`${percent_ess_ga || "--"}%`}
        </span>
      </div>
      <div className={styles["boost"]}>
        <span className={styles["comp-title"]}>ESS Boost:</span>
        <span className={styles["comp-content"]}>
          {boost_enable ? "Enabled" : "Disabled"}
        </span>
      </div>
      <div className={styles["rchgpower"]}>
        <span className={styles["comp-title"]}>Recharge Power Level:</span>
        <span className={styles["comp-content"]}>
          {`Level ${recharge_power_level || "--"}`}
        </span>
      </div>
    </Fragment>
  );
};
