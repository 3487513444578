import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styles from "./stackedGraphs.module.scss";
import { createRecoil, round, useFilteredSystems,  useSystems, useTimeOfDay, useTimeRange, useUser } from "@ecamion/sucrose"
import { getDateWithFixedHours, getUnixTimestamp } from '@utils/common';
import {  Column } from "@ant-design/plots"
import DatePicker from 'react-date-picker';

const antdTheme = {
    colors10: [
        "#FFB6BB",
        '#FF9BA1',
        '#FE7A82',
        '#DD6A72',
        '#BA5960',
        '#96484D',
        '#72373B',
        '#4F2629',
    ].reverse(),
    colors20: [
        "#FFB6BB",
        '#FF9BA1',
        '#FE7A82',
        '#DD6A72',
        '#BA5960',
        '#96484D',
        '#72373B',
        '#4F2629',
    ].reverse(),
}

const StackedGraphs = (props :any) => {
    const systems = useSystems();
    
    const [session] = useTimeOfDay({
        data: "session",
        systemIds: props.systemsIds,
        start: getUnixTimestamp(getDateWithFixedHours(props.startDate,true)),
        end: getUnixTimestamp(getDateWithFixedHours(props.endDate))
    });

    const [payment] = useTimeOfDay({
        data: "payment",
        systemIds: props.systemsIds,

        start: getUnixTimestamp(getDateWithFixedHours(props.startDate,true)),
        end: getUnixTimestamp(getDateWithFixedHours(props.endDate))
    });
    const sessionConfig: any = useMemo(() => {
        return transformTimeOfDay(session, systems, "sessions")
    }, [session.data, Object.keys(systems).length]);

    const paymentConfig: any = useMemo(() => {
        return transformTimeOfDay(payment, systems, "payment", 0.01)
    }, [payment.data, Object.keys(systems).length]);

    const [tab, setTab] = useState("session");

    function transformTimeOfDay(q: any, systems: any, yField: string, scale: number = 1) {
        const data = q.data ? structuredClone(q.data) : []
        data.sort((a: any, b: any) => {
            return ["morning", "afternoon", "evening", "night"].indexOf(a.time_of_day) - ["morning", "afternoon", "evening", "night"].indexOf(b.time_of_day)
        })
        data.forEach((d: any) => {
            d.type = systems[d.system_id]?.siteName
            d[yField] = round(Number(d[yField]) * scale, 1)
        })
        data.sort((a: any, b: any) => {
            return a.system_id - b.system_id
        })
        return {
            data,
            isStack: true,
            xField: 'time_of_day',
            yField: yField,
            seriesField: 'type',
            label: {
                position: 'middle', // 'top', 'bottom', 'middle'
            },
            interactions: [
                {
                    type: 'active-region',
                    enable: false,
                },
            ],
            connectedArea: {
                style: (oldStyle: any, element: any) => {
                    return {
                        fill: 'rgba(0,0,0,0.25)',
                        stroke: oldStyle.fill,
                        lineWidth: 0.5,
                    };
                },
            },
            theme: antdTheme,
        }
    }
    return (
        <div className={styles.stacked_container}>
            <div className={styles.stacked_header}>
                <div className={styles.stacked_header_left}>Sessions by TOD</div>
                <div className={styles.stacked_header_right}>
                    <div style={{ marginRight: "5%", zIndex: 9999 }}>
                        <DatePicker
                        onChange={(value: any) => {
                            props.setStartDate(value);
                        }}
                        value={props.startDate}
                        clearIcon={null}
                        />
                    </div>
                    <div style={{ marginRight: "5%", zIndex: 9999 }}>
                        <DatePicker
                        onChange={(value: any) => {
                            props.setEndDate(value);
                        }}
                        value={props.endDate}
                        clearIcon={null}
                        />
                    </div>
                    <div className={ tab === "session" ? styles.button_filter_active : styles.button_filter }
                        onClick={() => {setTab("session");}}>
                            Sessions
                    </div>
                    <div className={ tab === "payments" ? styles.button_filter_active : styles.button_filter }
                        onClick={() => {setTab("payments");}}>
                            Payments (Raw Currency)
                    </div>
                </div>
            </div>
            <div className={styles.stacked_body}>
                {tab === "session" ? <Column {...sessionConfig} /> : <Column {...paymentConfig} />}
            </div>
        </div>
    )
}


export default StackedGraphs