import { useEffect, useState, FunctionComponent } from "react";
import { Modal, Text, Input, Grid } from "@nextui-org/react";
import CostProfileSpec, {
  ConvertToTitle,
} from "@pages/pricingConfig/components/costProfileSpec";
import GroupMultiSelect, { CustomSystemsSelect } from "@pages/pricingConfig/components/groupMultiSelect";
import { IoTV2API } from "@services/index";
import {
  editableCostProfileToJsonObj,
  handleError,
  handleSuccess,
  renameKey,
  sleepThenUpdate,
} from "@utils/pricingConfig";
import {toast} from "react-toastify";
import { useSnackbar } from "notistack";
import CustomButton from "@pages/pricingConfig/components/customButton";
import TextAreaModal from "@pages/pricingConfig/components/textAreaModal";
import { IoTActionAPI, System, useSystems, useUser } from "@ecamion/sucrose";
import { MultiSelect, SelectItem } from "@mantine/core";

function CostProfileGridInput({
  labelLeft,
  labelRight,
  label,
  defaultValue,
  icon,
  readOnly = false,
  disabled = false,
  onChange,
  type = "number",
}: any) {
  return (
    <Grid>
      <Input
        required
        width={"200px"}
        //clearable
        bordered
        fullWidth
        size="lg"
        label={label}
        initialValue={defaultValue}
        value={defaultValue}
        onChange={onChange}
        contentLeft={icon}
        labelRight={labelRight}
        labelLeft={labelLeft}
        readOnly={readOnly}
        disabled={disabled}
        type={type}
      />
    </Grid>
  );
}

export default function EditableCostProfile({
  isCreate = false,
  costProfile,
  setCostProfile,
  visible,
  setVisible,
}: any) {
  const [groups, setGroups] = useState<any[]>([]);
  const [cp, setCp] = useState<any>({ ...costProfile });
  const [jsonImportVisible, setJsonImportVisible] = useState(false);
  const [json, setJson] = useState("");
  const setJsonFromCP = () => {
    setJson(JSON.stringify(editableCostProfileToJsonObj(cp), null, 2));
  };
  useEffect(() => {
    IoTV2API.get("groups").then((response) => {
      const groups = response.data;
      renameKey(groups, "id", "value");
      renameKey(groups, "name", "label");
      setGroups(groups);
    });
  }, []);
  useEffect(() => {
    setJsonFromCP();
  }, [cp]);
  useEffect(() => {
    setCp({ ...costProfile });
  }, [costProfile]);
  const { enqueueSnackbar } = useSnackbar();
  const closeHandler = () => {
    setVisible(false);
  };
  const systems = useSystems(true);
  const [filteredSystemsNumber, _] = useState<number[]>([])
  const [filteredSystems, setFilteredSystems] = useState<any>([]);
  const fSystems = Object.values(systems).filter((system: System) => system?.hasCustomerService)
  const fSystemsAsSelectItems: SelectItem[] = fSystems.map((system) => ({
    label: system.siteName, // Replace with the property you want to display in the dropdown
    value: String(system.id),    // Replace with the property you want to use as the 'value'
  }));
  const user = useUser()
  const applyToSystems = async (ss: any) => {
    const systems = fSystems.filter((system: System) => ss.includes(String(system.id)))
    const keys = Array.isArray(systems) ? systems : Object.keys(systems)
    for (let key of systems) {
        cp.system_id = key.id
        cp.created_by = user?.sub
        const response = await IoTActionAPI.post(`${key.name}/cost-profile`, cp).then((
            response) => {
            toast.success(`Successfully applied cost profile to ${key.name}`)
            IoTV2API.post('cost-profile', cp).then((response) => {
                // toast.success("Created successfully")
                // setVisible(false)
                // setCostProfile(response.data)
                // SleepThenUpdate(updateCostProfileStore).then()
            }).catch((error) => {
                handleError(error, enqueueSnackbar)
            })
        }
        ).catch((error) => {
          handleError(error, enqueueSnackbar)
        })
    }
    sleepThenUpdate().then()
}
  return (
    <>
      <div background-color="black">
        <TextAreaModal
          visible={jsonImportVisible}
          setVisible={setJsonImportVisible}
          title={"Import / Edit JSON"}
          text={json}
          setText={setJson}
          onCancel={() => {
            setJsonFromCP();
          }}
          helperText={
            "This is the JSON representation of the cost profile. You can edit it directly or import it from another cost profile."
          }
          onConfirm={(text: string) => {
            try {
              const json = JSON.parse(text);
              setCp({ ...cp, ...json, id: cp.id, version: cp.version });
              enqueueSnackbar("Imported successfully", { variant: "success" });
              setJsonImportVisible(false);
            } catch (e) {
              enqueueSnackbar("Invalid JSON", { variant: "error" });
            }
          }}
          label={"JSON"}
          placeholder={
            "You can copy the json of any other cost profile and paste it here"
          }
        />
        <Modal
          preventClose
          aria-labelledby="modal-title"
          open={visible}
          onClose={closeHandler}
          width={"60vw"}
        >
          <Modal.Header>
            <Text id="modal-title" b size={20}>
              {!isCreate ? (
                <>{`Editing - ${costProfile.name}`}</>
              ) : (
                "Creating Cost Profile"
              )}
            </Text>
          </Modal.Header>
          <Modal.Body>
          <MultiSelect
            data={fSystemsAsSelectItems}
            value={filteredSystems}
            onChange={(value) => {
              setFilteredSystems(value)
            }}
            searchable
            placeholder="Select systems"
            clearable
            multiple
            label="Systems"
          />
            <Grid.Container gap={2} css={{ padding: 0 }} justify={"flex-start"}>
              {Object.keys(CostProfileSpec).map((key: string) => {
                const spec = CostProfileSpec[key];
                const title = spec.title ?? ConvertToTitle(key);
                if (spec.readOnly && isCreate) {
                  return <div key={key} />;
                }
                return (
                  <CostProfileGridInput
                    key={key}
                    label={`${title}`}
                    defaultValue={cp[key]}
                    type={spec.type}
                    onChange={(e: any) => {
                      setCp({
                        ...cp,
                        [key]:
                          spec.type === "number"
                            ? parseFloat(e.target.value)
                            : e.target.value,
                      });
                    }}
                    icon={spec.icon}
                    disabled={spec.readOnly}
                    labelRight={
                      <span
                        style={{
                          width: "40px",
                          textAlign: "right",
                        }}
                      >{`${spec.prefix}${spec.suffix}`}</span>
                    }
                  />
                );
              })}
            </Grid.Container> 
          </Modal.Body>
          <Modal.Footer>
            <CustomButton
              cbuttonType={"transparent"}
              auto
              onClick={closeHandler}
            >
              Cancel
            </CustomButton>
            <CustomButton
              bold={false}
              auto
              onClick={() => {
                setJsonImportVisible(true);
              }}
            >
              Import / Edit JSON
            </CustomButton>
            <CustomButton
              bold={false}
              auto
              onClick={() => {
                if (!isCreate) {
                  if (JSON.stringify(costProfile) === JSON.stringify(cp)) {
                    enqueueSnackbar("No changes detected", {
                      variant: "default",
                    });
                    return;
                  }
                }
                  applyToSystems(filteredSystems)
                  setVisible(false);
              }}
            >
              {isCreate ? "Create and Apply" : "Save"}
            </CustomButton>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
