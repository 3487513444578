import React, { FunctionComponent } from "react";
import { Modal, Text } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useSnackbar } from "notistack";
import CustomButton from "@pages/pricingConfig/components/customButton";

const CodeModal: FunctionComponent<CodeModalProps> = (props) => {
  const { t: translation } = useTranslation();

  const closeHandler = () => {
    props.setVisible(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Modal
      closeButton
      aria-labelledby="modal-title"
      open={props.visible}
      onClose={closeHandler}
      width="700px"
    >
      <Modal.Header>
        <Text b id="modal-title" size={20} css={{ color: "#414141" }}>
          {props.title}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <SyntaxHighlighter
          language={props.language || translation("pricingConfig.codeModalLng")}
          style={docco}
        >
          {props.code}
        </SyntaxHighlighter>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          bold={false}
          auto
          onClick={() => {
            navigator.clipboard.writeText(props.code).then(() => {
              enqueueSnackbar("Copied to clipboard", { variant: "success" });
              props.setVisible(false);
            });
          }}
        >
          {translation("pricingConfig.copyToClipboard")}
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

interface CodeModalProps {
  visible: boolean;
  setVisible: Function;
  title: string;
  code: any;
  language?: string;
}

export default CodeModal;
