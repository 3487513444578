import { createSlice } from "@reduxjs/toolkit";

interface SocketIOStates {
  connected: string[];
}

const socketIOInitialState: SocketIOStates = {
  connected: [],
};

interface SetConnectedPayload {
  connected: boolean;
  namespace: string;
}

const socketIoSlice = createSlice({
  name: "socketIo",
  initialState: socketIOInitialState,
  reducers: {
    setConnected: (state, action) => {
      let allConnected: string[] = [...state.connected];
      if (action.payload.connected) {
        allConnected.push(action.payload.namespace);
      } else {
        allConnected = allConnected.filter(function (item) {
          return item !== action.payload.namespace;
        });
      }
      return {
        ...state,
        connected: allConnected,
      };
    },
  },
});

export const { setConnected } = socketIoSlice.actions;

export default socketIoSlice.reducer;
