import { useEffect, useState } from "react";
import styles from "./active-button.module.scss";

export const ActiveButton = ({
  active,
  setActive,
}: {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.button}
        onClick={() => {
          setActive(true);
        }}
        style={{
          color: `${active ? "#ffffff" : "#3c4042"}`,
          backgroundColor: `${active ? "#308aad" : "#ffffff"}`,
        }}
      >
        Active
      </div>
      <div
        className={styles.button}
        onClick={() => {
          setActive(false);
        }}
        style={{
          color: `${active ? "#3c4042" : "#ffffff"}`,
          backgroundColor: `${active ? "#ffffff" : "#308aad"}`,
        }}
      >
        Historical
      </div>
    </div>
  );
};

export default ActiveButton;


