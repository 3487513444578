
import React, {  useState, FunctionComponent, Fragment, useEffect, useMemo, useRef, useCallback } from "react";
import EmsModal from "@components/modal/emsModal";
import classes from "./chargerDetailsModal.module.scss";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MonitorIcon from '@mui/icons-material/Monitor';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DatePicker from "react-date-picker";
import {VncScreen} from "react-vnc";
import { IOT_HOST, IS_LOCAL, useSystemId } from "@ecamion/sucrose";
import { Switch } from "@mui/material";
import UptimeCalendarChart from "./uptimeCalendarChart";

const ChargerDetailsModal: FunctionComponent<ChargerDetailsModalProps>= (props) => {
    const [tab, setTab] = useState("vnc");
    const [systemId] = useSystemId();
    const [inclufdeOffline, setIncludeOffline] = useState(true);
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(endDate.getDate() - 7)));
    const vncScreenRef = useRef<React.ElementRef<typeof VncScreen>>(null);
    const [VNCConnected, setVNCConnected] = useState(false);

    const url = useMemo(() => {
        if (VNCConnected) {
            return IS_LOCAL ? `ws://${IOT_HOST}/vnc?id=SECC-${props.charger_id}`
                : `wss://${IOT_HOST}/vnc/system-${systemId}?id=SECC-${props.charger_id}`
        }
        return ""
    }, [props.charger_id, systemId, VNCConnected])

    const disconnectIfConnected = useCallback(() => {
        const {connected, disconnect} = vncScreenRef.current ?? {};
        if (connected) {
            console.log("VNC: disconnecting")
            disconnect?.();
        }
    }, [vncScreenRef])

    useEffect(() => {
        if (url && url !== "") {
            const {connect, connected, disconnect} = vncScreenRef.current ?? {};
            if (connected) {
                console.log("VNC: disconnecting")
                disconnect?.();
            }
            console.log("VNC: connecting " + url)
            connect?.();
        } else {
            disconnectIfConnected()
        }
    }, [disconnectIfConnected, url])

    const switchConnected = () => {
        console.log("VNC: switchConnected", VNCConnected)
        if (VNCConnected) {
            disconnectIfConnected();
            setVNCConnected(false);
        }else{
            setVNCConnected(true);
        }
        
    }

    return (
        <Fragment> 
            <EmsModal open={props.showCargerDetails} modal_width={"60%"} modal_height={"auto"}>
                <div className={classes.modal_con}>
                    <div className={classes.modal_header_row}>
                        <div className={classes.modal_tabs}>
                            <div className={ tab === "vnc" ? classes.button_filter_active : classes.button_filter }
                                onClick={() => {setTab("vnc");}}>
                                    <MonitorIcon /> VNC
                            </div>
                            <div className={ tab === "uptime" ? classes.button_filter_active : classes.button_filter }
                                onClick={() => {setTab("uptime");}}>
                                    <WatchLaterIcon /> Uptime
                            </div>
                        </div>
                        <div className={classes.event_log__close_button}>
                            <IconButton aria-label="close" onClick={()=>{props.setShowCargerDetails(false);disconnectIfConnected();setVNCConnected(false);}}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <hr />
                    <div className={classes.modal_body}>
                        {tab === "vnc" && 
                            <>
                                <div className={classes.subheader} >
                                    <div className={classes.stacked_header_left}>
                                        {!VNCConnected ? <div className={classes.button_filter }
                                            onClick={() => {switchConnected();}}>
                                                Connect
                                        </div> : null}
                                    </div>
                                    <div className={classes.stacked_header_right}>
                                        {VNCConnected ?   <div className={  classes.button_filter_active }
                                            onClick={() => {switchConnected();}}>
                                               Disconnect
                                        </div>  : null}

                                    </div>
                                </div>
                                <div className={classes.container}>
                                    {VNCConnected ? 
                                        <VncScreen
                                            url={url}
                                            scaleViewport
                                            background="#b4b4b4Jule"
                                            style={{
                                                width: '670px',
                                                height: '420px',
                                            }}
                                            ref={vncScreenRef}
                                        /> :
                                        <div style={{display: "flex", justifyContent: "center", alignItems: "stretch", padding:"20%"}}>
                                            <label style={{fontSize: "1.5em"}}>VNC Disconnected</label>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {tab === "uptime" &&
                            <>
                                <div className={classes.subheader}>
                                    <div className={classes.stacked_header_left}>
                                        <div className={ inclufdeOffline ? classes.button_filter_active : classes.button_filter }
                                            onClick={() => {setIncludeOffline(!inclufdeOffline);}}>
                                                Include System Online
                                        </div>
                                    </div>
                                    <div className={classes.stacked_header_right}>
                                        <div style={{ marginRight: "5%", zIndex: 9999 }}>
                                            <DatePicker
                                                onChange={(value: any) => {
                                                    setStartDate(value);
                                                }}
                                                value={startDate}
                                                clearIcon={null}
                                            />
                                        </div>
                                        <div style={{marginRight: "5%", zIndex: 9999 }}>
                                            <DatePicker
                                                onChange={(value: any) => {
                                                    setEndDate(value);
                                                }}
                                                value={endDate}
                                                clearIcon={null}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.container}>
                                    <UptimeCalendarChart
                                        inclufdeOffline={inclufdeOffline}
                                        systemId={systemId}
                                        asSECCId={props.charger_id}
                                        start={ startDate }
                                        end={ endDate }
                                    ></UptimeCalendarChart>
                                </div>
                            </>
                        }

                                
                    </div>
                </div>
            </EmsModal>
        </Fragment>
    )
}

interface ChargerDetailsModalProps {
    showCargerDetails : boolean
    setShowCargerDetails : Function
    charger_id : number
  }


export default ChargerDetailsModal