import { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import location_logo from "@view/SideBar/location-sharp.png";
import styles from "../../sideBar.module.scss";
import { setSideBarInfo } from "@store";

const SideBarItemLocation: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div
      className={styles.category}
      onClick={() => {
        dispatch(setSideBarInfo({ category: "location" }));
        navigate("/landmark_chargehub");
      }}
    >
      <div className={styles.categoryInnner}>
        <div className={styles.categoryImgContainer}>
          <img className={styles.categoryImg} alt={""} src={location_logo} />
        </div>
        <div className={styles.categoryWord}>Location</div>
      </div>
    </div>
  );
};

export default SideBarItemLocation;
