import { useEffect, useState, FunctionComponent } from "react";
import { Chart } from "react-google-charts";
import HeadBar from "@components/headBar";
import styles from "./tempHistory.module.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useMetrics } from "@ecamion/sucrose";
import configData from "@config/config";
import { getDateWithFixedHours, getUnixTimestamp } from "@utils/common";

const TempHistory: FunctionComponent = () => {
  const { t: translation } = useTranslation();
  const systemStates = useSelector((state: any) => {
    return {
      id: state.system.id,
    };
  });

  
  const location: any = useLocation();

  const headBarTitle = location.state.headbarTitle || "Temperature History";

  const [selected, setSelected] = useState(0);
  const unixPreviousDateTime = getUnixTimestamp(getDateWithFixedHours( new Date(new Date().getTime() - 1 * 24 * 60 * 60000), true));
  const unixPreviousWKDateTime = getUnixTimestamp(getDateWithFixedHours( new Date(new Date().getTime() - 7* 24 * 60 * 60000), true));
  const unixEndDate = getUnixTimestamp(getDateWithFixedHours(configData.meteringConfig.endDate, false));
  const [graphDay, setGraphDay] = useState<any>([[new Date(), 0, 0, 0]]);
  const [graphWeek, setGraphWeek] = useState<any>([[new Date(), 0, 0, 0]]);
  const [title, setTitle] = useState("");
  const [display, setDisplay] = useState([]);

  useEffect(() => {
    selected === 0 ? setTitle("Days") : setTitle("Weeks");
  }, [selected]);

  useEffect(() => {
    selected === 1 ? setDisplay(graphWeek) : setDisplay(graphDay);
  }, [graphDay, selected]);

  useMetrics({
    fields: [
      {
        "group": "EssStatus",
        "field": "CellAvgTemp",
        "start": unixPreviousDateTime ,
        "end": unixEndDate,
        "system": systemStates.id,
        "precision": "1m",
        "aggregate":"avg"
      },
      {
        "group": "EssStatus",
        "field": "CellMinTemp",
        "start": unixPreviousDateTime  ,
        "end": unixEndDate,
        "system": systemStates.id,
        "precision": "1m",
        "aggregate":"avg"
      },
      {
        "group": "EssStatus",
        "field": "CellMaxTemp",
        "start":  unixPreviousDateTime ,
        "end": unixEndDate,
        "system": systemStates.id,
        "precision": "1m",
        "aggregate":"avg"
      },      
      {
        "group": "EssStatus",
        "field": "CellAvgTemp",
        "start": unixPreviousWKDateTime ,
        "end": unixEndDate,
        "system": systemStates.id,
        "precision": "1h",
        "aggregate":"avg"
      },
      {
        "group": "EssStatus",
        "field": "CellMinTemp",
        "start": unixPreviousWKDateTime ,
        "end": unixEndDate,
        "system": systemStates.id,
        "precision": "1h",
        "aggregate":"avg"
      },
      {
        "group": "EssStatus",
        "field": "CellMaxTemp",
        "start": unixPreviousWKDateTime ,
        "end": unixEndDate,
        "system": systemStates.id,
        "precision":  "1h",
        "aggregate":"avg"
      } 
      
    ],
    transformer: (data: any) => {
      const aggregated: any = {}
      if(!data || !data[systemStates.id]?.["EssStatus"]){
        return null;
      }  
      const dataArray =  {
        "week" : [] as any[],
        "day" : [] as any[]
      }
      Object.entries(data[systemStates.id]?.["EssStatus"]).forEach(([CellLabel, CellData]: any[]) => {
        if (CellLabel.includes("1h")) {
          dataArray["week"].push(CellData);
        } else {
          dataArray["day"].push(CellData);
        }
      })
      


      

      const dataGrouping = (array : any []) => {
        const groupedData = new Map();
        array.forEach((dict : any[]) => {
          for (const [timestamp, value] of Object.entries(dict)) {
              if (!groupedData.has(timestamp)) {
                  groupedData.set(timestamp, [new Date( parseInt(timestamp) *1000)]);
              }
              groupedData.get(timestamp).push( value[0] );
          }
        });
        return Array.from(groupedData.values());
      }

      setGraphDay(dataGrouping(dataArray["day"]));
      setGraphWeek(dataGrouping(dataArray["week"]));
    },
    refetchInterval:  15 * 60 * 1000,
  });


  return (
    <div className={styles.root_container}>
      <HeadBar title={headBarTitle} />
      <div className={styles.main_container}>
        <div className={styles.divB}>
          <div className={styles.divB_A}>
            <div className={styles.divB_A_item1}>
              <div
                className={
                  selected === 0
                    ? styles.divB_A_subitem
                    : styles.divB_A_subitemB
                }
                onClick={() => {
                  setSelected(0);
                }}
              >
                {translation("tempHistory.today")}
              </div>
            </div>
            <div className={styles.divB_A_item2}>
              <div
                className={
                  selected === 1
                    ? styles.divB_A_subitem
                    : styles.divB_A_subitemB
                }
                onClick={() => {
                  setSelected(1);
                }}
              >
                {translation("tempHistory.week")}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.divC}>
          <Chart
            width={"100%"}
            height={"100%"}
            chartType="ScatterChart"
            loader={<div>Loading Chart</div>}
            data={[["time", "Average", "Min", "Max"], ...display]}
            options={{
              pointSize: 0.5,
              hAxis: {
                title: title,
              },
              vAxis: {
                title: "Degree",
              },
              colors: ["#a52714", "#097138", "blue"],
              crosshair: {
                color: "#000",
                trigger: "selection",
              },
              legend: { pointSize: 2 },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TempHistory;
