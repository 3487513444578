import { FunctionComponent, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../../sideBar.module.scss";
import pricing_logo from "@view/SideBar/pricing_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { setSideBarInfo } from "@store";

const SideBarItemPricingConfig: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/pricing_config") {
      dispatch(setSideBarInfo({ category: "pricing" }));
    }
  }, [location.pathname]);

  const sidebarState = useSelector((state: any) => {
    return {
      category: state.sidebar.category,
    };
  });

  return (
    <div
      className={styles.category}
      style={
        sidebarState.category === "pricing"
          ? { backgroundColor: "#eaeaea" }
          : undefined
      }
      onClick={() => {
        dispatch(setSideBarInfo({ category: "pricing" }));
        navigate("/pricing_config", {
          state: { headbarTitle: "Pricing Configuration" },
        });
      }}
    >
      <div
        className={
          sidebarState.category === "pricing"
            ? styles.categoryInner_selected
            : styles.categoryInnner
        }
      >
        <div className={styles.categoryImgContainer}>
          <img className={styles.categoryImg} alt={""} src={pricing_logo} />
        </div>
        <div className={styles.categoryWord}>Pricing Configuration</div>
      </div>
    </div>
  );
};

export default SideBarItemPricingConfig;
