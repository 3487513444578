export const resetSite = (site: any[]) => {
  for (let i = 0; i < site.length; i++) {
    site[i].selected = true;
  }
};

export const findSiteById = (site_id: any, site: any[]) => {
  for (let i = 0; i < site.length; i++) {
    if (site[i].id === site_id) {
      return { ...site[i] };
    }
  }
  return -1;
};

export const countSeconds = (str: string) => {
  const [hh = "0", mm = "0", ss = "0"] = (str || "0:0:0").split(":");
  const hour = parseInt(hh, 10) || 0;
  const minute = parseInt(mm, 10) || 0;
  const second = parseInt(ss, 10) || 0;
  return hour * 3600 + minute * 60 + second;
};


export const hasDuplicate = (originalArray, newArray) => {
  return originalArray.filter(item => newArray.includes(item));
}