import "./index.css";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import store from "./store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FieldProvider } from "@services";
import { RecoilRoot } from "recoil";
import {Flip, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
  zIndex: {
    snackbar: 10000,
  },
});

const queryClient = new QueryClient();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <FieldProvider>
            <ToastContainer autoClose={3000} pauseOnFocusLoss={false} position={"bottom-right"}
                                        transition={Flip}
                            style={{ zIndex: "10000" }} />
              <App />
            </FieldProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </RecoilRoot>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);
