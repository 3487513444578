
import React, {FunctionComponent} from "react";
import "react-circular-progressbar/dist/styles.css";

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

const ProgressCircle: FunctionComponent<ProgressCircleProps> = (props) => {
  const { barValue, barColor, numValue, unit } = props;
  return (
    <CircularProgressbarWithChildren
      value={barValue}
      strokeWidth={14}
      styles={buildStyles({
        strokeLinecap: "butt",
        pathColor: barColor,
      })}
    >
      <div style={{ color: "#6c757d", textAlign: "center" }}>
        <div>{Math.abs(numValue)}</div>
        <div style={{ textAlign: "center" }}>{unit}</div>
      </div>
    </CircularProgressbarWithChildren>
  );
}

interface ProgressCircleProps{
  barValue: number;
  barColor: string;
  numValue: number;
  unit?: string;
}

export default ProgressCircle;
