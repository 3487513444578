import { FunctionComponent } from "react";
import { Modal, Spacer, Text } from "@nextui-org/react";
import CostProfile from "./costProfile";
import { IoTV2API } from "@services/index";
import { useSnackbar } from "notistack";
import { handleError, handleSuccess } from "@utils/pricingConfig";
import { useDispatch, useSelector } from "react-redux";
import { setCostProfiles } from "@store";
import CustomButton from "./customButton";
import { useTranslation } from "react-i18next";

const DeleteCostProfileModal: FunctionComponent<DeleteCostProfileModalProps> = (
  props
) => {
  const { t: translation } = useTranslation();

  const costProfiles = useSelector(
    (state: any) => state.costProfiles.costProfiles
  );

  const dispatch = useDispatch();

  const closeHandler = () => {
    props.setVisible(false);
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <Modal
      closeButton
      aria-labelledby="modal-title"
      open={props.visible}
      onClose={closeHandler}
      width="fit-content"
    >
      <Modal.Header>
        <Spacer x={2} />
        <Text b id="modal-title" size={20} css={{ color: "#F31260" }}>
          {`Are you sure you want to delete - ${props.costProfile.name}?`}
        </Text>
        <Spacer x={2} />
      </Modal.Header>
      <Modal.Body>
        <CostProfile
          costProfile={props.costProfile}
          showTitle={false}
          padding={0}
        />
      </Modal.Body>
      <Modal.Footer>
        <CustomButton cbuttonType={"transparent"} auto onClick={closeHandler}>
          Cancel
        </CustomButton>
        <CustomButton
          cbuttonType="error"
          auto
          onClick={() => {
            IoTV2API.delete(`cost-profile/${props.costProfile.id}`)
              .then((response) => {
                handleSuccess(enqueueSnackbar);
                props.setVisible(false);
                dispatch(
                  setCostProfiles(
                    costProfiles.filter((cp: any) => {
                      return cp.id !== props.costProfile.id;
                    })
                  )
                );
              })
              .catch((error) => {
                handleError(error, enqueueSnackbar);
              });
          }}
        >
          {translation("pricingConfig.confirmButton")}
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

interface DeleteCostProfileModalProps {
  costProfile: any;
  visible: boolean;
  setVisible: Function;
}

export default DeleteCostProfileModal;
