import React, { useEffect, useState, FunctionComponent } from "react";

import styles from "./tempMeter.module.scss";

const TempMeter: FunctionComponent<TempMeterProps> = (props) => {
  const [temConfig, setTempConfig] = useState<any>({
    textColor: "green",
  });

  useEffect(() => {
    let tempConfig: any = {};

    if (props.color === "orange") {
      tempConfig.textColor = "#aa2419";
    } else if (props.color === "yellow") {
      tempConfig.textColor = "#ea9c00";
    }
    setTempConfig(tempConfig);
  }, [props.color]);

  return (
    <div className={styles.meter_container}>
      <div className={styles.meter_title}>{props.title}</div>
      <div className={styles.indicator_con}>
        <div
          className={styles.bottom_indicator}
          style={{ color: temConfig.textColor }}
        >
          {`${props.data} °C`}
        </div>
        <div
          className={styles.bottom_indicator}
          style={{ color: temConfig.textColor }}
        >
          {`${Math.round(((props.data * 9) / 5 + 32) * 10) / 10} °F`}
        </div>
      </div>
    </div>
  );
};

interface TempMeterProps {
  data: number;
  color: string;
  title: string;
}

export default TempMeter;