const configData: any = {
  socketIOConfig: {
    EventType: {
      CHARGER: "CHARGER_MESSAGE",
      CONTROLLER: "CONTROLLER_MESSAGE",
      CLIENT: "CLIENT_MESSAGE",
      CONN: "CONN_MESSAGE",
      JOIN_ROOM: "JOIN_ROOM",
    },
    socketIOHost: process.env.REACT_APP_SOCKETIO_SERVER,
  },
  defaultCostProfile: {
    system_id: "",
    profile_name: "",
    active_profile: false,
    creation_time: new Date(),
    modified_time: new Date(),
    price_currency: 0,
    tax_rate: 13,
    connection_fee: 1,
    curtail_fee: 0.05,
    curtail_energy_max: 12,
    speed_1_power: 75,
    speed_1_fee: 0.32,
    speed_2_power: 125,
    speed_2_fee: 0.42,
    speed_3_power: 150,
    speed_3_fee: 0.52,
  },
  meteringConfig: {
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
  },
  userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  timeInterval: 5
};

export default configData;
