export const benderDecode = (code: number) => {
  if (code === 0) {
    return "N/A";
  } else if (code === 1) {
    return "Not Installed";
  } else if (code === 2) {
    return "Unreadable";
  } else if (code === 3) {
    return "OK";
  } else {
    return "Faulted";
  }
};
