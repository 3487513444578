import { FunctionComponent, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../../sideBar.module.scss";
import jule_logo from "@view/SideBar/jule_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { setSideBarInfo } from "@store";

const SideBarItemChargingDispensers: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/charging_dispensers") {
      dispatch(setSideBarInfo({ category: "charger" }));
    }
  }, [location.pathname]);

  const sidebarState = useSelector((state: any) => {
    return {
      category: state.sidebar.category,
    };
  });

  return (
    <div
      className={styles.category}
      style={
        sidebarState.category === "charger"
          ? { backgroundColor: "#eaeaea" }
          : undefined
      }
      onClick={() => {
        dispatch(setSideBarInfo({ category: "charger" }));
        navigate("/charging_dispensers", {
          state: { headbarTitle: "EV Charging Dispensers" },
        });
      }}
    >
      <div
        className={
          sidebarState.category === "charger"
            ? styles.categoryInner_selected
            : styles.categoryInnner
        }
      >
        <div className={styles.categoryImgContainer}>
          <img className={styles.categoryImg} alt={""} src={jule_logo} />
        </div>
        <div className={styles.categoryWord}>EV Charging Dispensers</div>
      </div>
    </div>
  );
};

export default SideBarItemChargingDispensers;
