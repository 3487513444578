import { useEffect, useState, useRef, FunctionComponent } from "react";
import { getDateWithFixedHours, getDaysArray, getUnixTimestamp } from "@utils/common";

import Box from "@mui/material/Box";
import DatePicker from "react-date-picker";
import HeadBar from "@components/headBar";
import BarChart from "@components/metering/barChart";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import configData from "@config/config";
import styles from "./meterSummary.module.scss";
import { useLocation } from "react-router-dom";
import EnergyPrice from "./components/energyPrice";
import { MetricsParams, useMetrics } from "@ecamion/sucrose";

const Metering: FunctionComponent = () => {
  const { t: translation } = useTranslation();

  const systemStates = useSelector((state: any) => {
    return {
      id: state.system.id === -1
          ? localStorage.getItem("system") !== null
          ? JSON.parse(localStorage.getItem("system") as string)["id"]
          : ""
          : state.system.id,
    };
  });

  const location: any | undefined = useLocation();

  const headBarTitle = location?.state?.headbarTitle || "Metering";

  const [meterType, setMeterType] = useState<number>(1);
  const [startDate, setStartDate] = useState<Date>(
    configData.meteringConfig.startDate
  );
  const [endDate, setEndDate] = useState<Date>(
    configData.meteringConfig.endDate
  );
  const [dates, setDates] = useState<string[]>([]);
  const [dataDelivered, setDataDelivered] = useState<number[]>([]);
  const [dataReceived, setDataReceived] = useState<number[]>([]);

  const handleChange = (event: any) => {
    setMeterType(event.target.value);
  };
  
  useMetrics({
    fields: [
      {
        group: meterType === 1 ? "M2" : "M3",
        field: "TotWhPos",
        start: getUnixTimestamp(getDateWithFixedHours(startDate, true)),
        end: getUnixTimestamp(getDateWithFixedHours(endDate, false)),
        precision: "1day",
        system: systemStates.id,
        aggregate: "diff",
      },
      {
        group: meterType === 1 ? "M2" : "M3",
        field: "TotWhNeg",
        start: getUnixTimestamp(getDateWithFixedHours(startDate, true)),
        end: getUnixTimestamp(getDateWithFixedHours(endDate, false)),
        precision: "1day",
        system: systemStates.id,
        aggregate: "diff",
      },
    ],
    transformer: (data: any) => {
      const dArr = getDaysArray(
        startDate,
        endDate
      )
      setDates(dArr);

      
      const mType = meterType === 1 ? "M2" : "M3";
      const dataArrayPos = data[systemStates.id]?.[mType]?.["TotWhPos.1day.diff."];
      const dataArrayNeg = data[systemStates.id]?.[mType]?.["TotWhNeg.1day.diff."];
      if (dataArrayPos) {
        const whPos = Object.values(dataArrayPos).map((whValue: any) => {
          return (parseFloat((whValue[0] / 1000).toFixed(2)));
        })
        setDataDelivered(whPos);
      }
      if (dataArrayNeg) {
        const whNeg = Object.values(dataArrayNeg).map((whValue: any) => {
          return (parseFloat((whValue[0] / 1000).toFixed(2)));
        })
        setDataReceived(whNeg);
      }
    },
    refetchInterval: false
  });

  return (
    <div className={styles.root_container}>
      <HeadBar title={headBarTitle} />
      <div className={styles.main_container}>
        <div className={styles.param_con}>
          <div className={styles.param_itemA}>
            <div
              className={[styles.param_header_con, styles.header1].join(" ")}
            >
              {translation("meterSummary.selectModule")}
            </div>
            <div className={styles.param_itemA_B}>
              <Box sx={{ width: "100%", height: "100%" }}>
                <Select
                  value={meterType}
                  onChange={handleChange}
                  sx={{
                    width: "100%",
                    height: "100%",
                    fontSize: "1vw",
                    fontWeight: 600,
                    color: "#5f6468",
                  }}
                >
                  <MenuItem value={1}>ChargeHub (M2)</MenuItem>
                  <MenuItem value={2}>Telecom (M3)</MenuItem>
                </Select>
              </Box>
            </div>
          </div>
          <div className={styles.param_itemB}>
            <div
              className={[styles.param_header_con, styles.header1].join(" ")}
            >
              {`${translation("meterSummary.specifyDateRange")}:`}
            </div>
            <div className={styles.picker_row}>
              <div className={styles.picker_item}>
                <DatePicker
                  onChange={(value: any) => {
                    setStartDate(value);
                  }}
                  value={startDate}
                  clearIcon={null}
                />
              </div>
              <div className={styles.picker_item}>
                <DatePicker
                  onChange={(value: any) => {
                    setEndDate(value);
                  }}
                  value={endDate}
                  clearIcon={null}
                />
              </div>
            </div>
          </div>
          <EnergyPrice
            dataDelivered={dataDelivered}
            dataReceived={dataReceived}
          />
        </div>
        <div className={styles.bar_chart}>
          <BarChart
            dataDelivered={dataDelivered}
            dataReceived={dataReceived}
            dates={dates}
            meterType={meterType}
          ></BarChart>
        </div>
      </div>
    </div>
  );
};

export default Metering;
