import { useState, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../../sideBar.module.scss";
import { signOut } from "@constants/index";
import { setSideBarInfo } from "@store";
import { useUser } from "@ecamion/sucrose";

const SideBarItemProfile: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isSettingSelected, setIsSettingSelected] = useState(false);

  const sidebarState = useSelector((state: any) => {
    return {
      category: state.sidebar.category,
    };
  });

  const userInfo = useUser();

  return sidebarState.category === "profile" ? (
    <div
      className={styles.categoryGA}
      style={{
        backgroundColor:
          sidebarState.category === "profile" ? "#eaeaea" : undefined,
        marginTop: "30%",
        height: "20%",
      }}
    >
      <div className={styles.categoryGAOutter}>
        <div className={styles.categoryGAInnner}>
          <div className={styles.categoryImgContainer}>
            <img className={styles.userIcon} alt={""} src={userInfo?.picture} />
          </div>
          <div className={styles.categoryWordSelected}>User</div>
        </div>
        <div className={styles.subcategoryGAInnner} style={{ height: "60%" }}>
          <div
            className={
              isSettingSelected
                ? styles.subcategoryGAtext_selected
                : styles.subcategoryGAtext
            }
            onClick={() => {
              setIsSettingSelected(true);
              navigate("/user", {
                state: { headbarTitle: "User Profile" },
              });
            }}
          >
            Profile
          </div>
          <div className={styles.subcategoryGAtext} onClick={signOut}>
            Sign Out
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={styles.category}
      style={
        sidebarState.category === "profile"
          ? { backgroundColor: "#eaeaea", marginTop: "30%" }
          : { marginTop: "30%" }
      }
      onClick={() => {
        dispatch(setSideBarInfo({ category: "profile" }));
        setIsSettingSelected(false);
      }}
    >
      <div
        className={
          sidebarState.category === "profile"
            ? styles.categoryInner_selected
            : styles.categoryInnner
        }
      >
        <div className={styles.categoryImgContainer}>
          <img className={styles.userIcon} alt={""} src={userInfo?.picture} />
        </div>
        <div className={styles.categoryWord}>User</div>
      </div>
    </div>
  );
};

export default SideBarItemProfile;
