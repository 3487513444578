import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import LocationMap from "./components/map";
import NavigationBar from "@components/navigationBar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./landmarkChargehub.module.scss";
import LocationList from "./components/table";
import { setSideBarInfo, setSystemState } from "@store";
import {  useSelectedSystem, useSystemId, useSystems } from "@ecamion/sucrose";
import Revenue from "./components/revenue";
import HistoricalSessions from "./components/historicalSessions/index";
import StackedGraphs from "./components/stackedGraphs/index";

const Landmarkchargehub = () => {
  const [displayOption, setDisplayOption] = useState<string>("locations");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  const [, setSystemId] = useSystemId();
  const systems = useSystems(true);
  const selectedSystem : any = useSelectedSystem(true);
  const systemsIds=Object.values(systems).map((s:any) => s.id);
  const onSytemViewClick = () => {
    dispatch(setSideBarInfo({ category: "dashboard" }));
    navigate("/site_overview", {
      state: { headbarTitle: "Dashboard" },
    });
  };



  useEffect(() => {
    setSystemId(-1)
  }, [])
  const { id, storeId, fgid } = selectedSystem || {};

  const storeName =
    storeId || fgid
      ? `Store # ${storeId !== undefined ? storeId : "--"}${
          fgid ? ` (${fgid})` : ""
        }`
      : `System # ${id || 0}`;
      const [endDate, setEndDate] = useState(new Date());
      const [startDate, setStartDate] = useState(
        new Date(new Date().setDate(endDate.getDate() - 1))
    );
  return (
    <div className={styles.root_container}>
      <NavigationBar displayOption={displayOption}  setDisplayOption={setDisplayOption}/>

      <div className={styles.main_container}>
        {displayOption === 'locations' ? 
          <div className={styles.submain_container}>
            <div style={{ width: "60%", height: "90%", marginLeft: "auto" }}>
              <LocationMap
                selected={selectedSystem}
                systems={Object.keys(systems).map(
                  (key:any) => systems[key]
                )}
              />
            </div>
            <div className={styles.boarding_con_right} style={{ width: "30%" }}>
              {!selectedSystem && systems && (
                <LocationList
                  systems={Object.keys(systems).map(
                    (key:any) => systems[key]
                  )}
                />
              )}
              {selectedSystem && (
                
                <>
                  <div
                    className={styles.site_overview_detail}
                    onClick={() => {
                      dispatch(setSystemState({ id: -1 }));
                      setSystemId(-1);
                    }}
                  >
                    Back
                  </div>
                  <p className={styles.customer_name}>
                    {selectedSystem.siteName}
                  </p>
                  <p className={styles.store_number}>{storeName}</p>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      maxHeight: "35%",
                      paddingTop: "5px",
                    }}
                  >
                    <object
                      data={`https://static.julecharging.com/chargehub/location-${selectedSystem.id}.png`}
                      type="image/png"
                      style={{ width: "80%", maxHeight: "90%" }}
                      key={selectedSystem.id}
                    >
                      <img
                        alt={selectedSystem.siteName || "Unknown Location"}
                        src="https://static.julecharging.com/chargehub/location-default.png"
                        className={styles.site_image}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </object>
                  </div>

                  <div>
                    <p className={styles.store_location}>
                      {selectedSystem.address}
                    </p>
                  </div>
                  <div className={styles.boarding_station_container}>
                    <div className={styles.boarding_station_row}>
                      <p className={styles.boarding_station_status}>
                        {`${translation("landmarkChargehub.stationStatus")}`}:
                      </p>
                      <p
                        className={styles.boarding_station_connected}
                        style={{
                          color: `${selectedSystem.alive ? "green" : "red"}`,
                        }}
                      >
                        {selectedSystem.alive
                          ? "Chargehub Connected"
                          : "Chargehub Disconnected"}
                      </p>
                    </div>
                  </div>

                  <div
                    className={styles.site_overview_detail}
                    onClick={onSytemViewClick}
                  >
                    {translation("landmarkChargehub.siteManagementSystem")}
                  </div>
                </>
              )}
            </div>
          </div>
          : displayOption === 'historical' ?
            <div className={styles.submain_container}>
              <HistoricalSessions
                systemsIds={systemsIds}
                startDate = { startDate}
                setStartDate = { setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}/>
            </div>
          : displayOption === 'revenue' ?
            <div className={styles.submain_container}>
              <Revenue
                systemsIds={systemsIds}
                startDate = { startDate}
                setStartDate = { setStartDate}
                endDate={endDate}
                setEndDate={setEndDate} />
            </div>
          : displayOption === 'stacked' ?
            <div className={styles.submain_container}>
              <StackedGraphs
                systemsIds={systemsIds}
                startDate = {startDate}
                setStartDate = {setStartDate}
                endDate={endDate}
                setEndDate={setEndDate} />
            </div>
          : <></>
        }
      </div>
    </div>
  );
};

export default memo(Landmarkchargehub);
