import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

interface SystemState {
  id: number;
}

const getInitialState = () => {
  const id = localStorage.getItem("id");
  return {
    id: id ? parseInt(id) : -1,
  } as SystemState;
};

export const systemSlice = createSlice({
  name: "system",
  initialState: getInitialState,
  reducers: {
    setSystemState: (state, action) => {
      if (action.payload?.id) {
        localStorage.setItem("id", action.payload.id.toString());
      }
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setSystemState } = systemSlice.actions;
export const useSystemState = () =>
  useSelector((state: any) => state.system.id as number);

export default systemSlice.reducer;
