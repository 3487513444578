import { useState, FunctionComponent } from "react";
import { DataGrid, GridToolbar, GridColumns } from "@mui/x-data-grid";
import DatePicker from "react-date-picker";
import HeadBar from "@components/headBar";
import styles from "./chargingReports.module.scss";

import { getUnixTimestamp, getDateWithFixedHours } from "@utils/common";
import { MetricsParams,  System,  useMetrics, useSelectedSystem } from "@ecamion/sucrose";
import { SessionType } from "@services/sse/types";

const columns: GridColumns<SessionType> = [
  {
    field: "trans_id",
    headerName: "Transaction ID",
    minWidth: 150,
  },
  {
    field: "timetrans",
    headerName: "Transaction Date/Time",
    minWidth: 200,
    renderCell: (params: any) =>
      new Date(params.row.timetrans).toLocaleString(),
  },
  {
    field: "charger_id",
    headerName: "Charger ID",
    minWidth: 100,
    align: "center",
    // valueFormatter: (value: any) => {
    //   return (
    //     <div style={{ display: "flex" }}>
    //       <div style={{ width: "15%" }}>
    //         <img src={redirection} alt={""} style={{ marginLeft: "10%" }} />
    //       </div>
    //       <div style={{ width: "70%" }}>{value}</div>
    //     </div>
    //   );
    // },
  },
  {
    field: "fgid",
    headerName: "FG ID",
    minWidth: 100,
    align: "center",
  },
  {
    field: "energy_usage",
    headerName: "Energy Usage (kWh)",
    minWidth: 150,
    align: "center",
    valueFormatter: (value: any) => {
      return (value.value).toFixed(1) + " kWh";
    },
  },
  {
    field: "peak_demand",
    headerName: "Peak Demand (kW)",
    minWidth: 150,
    align: "center",
    valueFormatter: (value: any) => {
      return (value.value).toFixed(1) + " kW";
    },
  },
  {
    field: "average_demand",
    headerName: "Average Demand (kW)",
    minWidth: 170,
    align: "center",
    valueFormatter: (value: any) => {
      return (value.value).toFixed(1) + " kWh";
    },
  },
  {
    field: "charge_interval",
    headerName: "Charge Duration (hh:mm:ss)",
    minWidth: 200,
    align: "center",
    valueGetter: (value: any) => {
      return new Date(value.value * 1000)
        .toISOString()
        .slice(11, 19);
    },
  },
  {
    field: "session_cost",
    headerName: "Session Cost ($)",
    minWidth: 120,
    align: "center",
    valueGetter: (value: any) => {
      return (value.row.session_cost / 100).toFixed(2);
    },
  },
];

const ChargingReports: FunctionComponent = () => {
  const headBarTitle = "Charging Reports";
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(endDate.getDate() - 10))
  );


  const selectedSystem : System | any = useSelectedSystem() || {};

  const [sessions] = useMetrics<readonly SessionType[]>({
    fields: [
      {
        group: "public.session",
        start: getUnixTimestamp(getDateWithFixedHours(startDate,true),),
        end: getUnixTimestamp(    getDateWithFixedHours(endDate,false)),
        system: selectedSystem.id,
      },
    ],
    transformer: (data: MetricsParams<string | number>) : readonly SessionType[] => {
      let d: any[] = [];
      Object.values(data).forEach((systemData : any) => {
        if (systemData["public.session"]?.["..."]) {
          d = Object.values(systemData["public.session"]?.["..."]).flat(3)
          .map((item : any , i: number ) => {
              return {
                id: i,
                trans_id: item.unitransid,
                timetrans: item.timetrans,
                charger_id: item.charger_id,
                fgid: selectedSystem.fgid,
                energy_usage: item.totenergydeli,
                peak_demand: item.maxpowerdeli,
                average_demand: item.avepowerdeli,
                charge_interval: item.totsessdur,
                session_cost: item.totpaymentamt,
              }
            }
          );
        }
      });
      return d;
    },
  }); 
  return (
    <div className={styles.root_container}>
      <HeadBar title={headBarTitle} />
      <div className={styles.main_container}>
        <div className={styles.query_con}>
          <div style={{ marginRight: "5%", zIndex: 9999 }}>
            <DatePicker
              onChange={(value: any) => {
                setStartDate(value);
              }}
              value={startDate}
              clearIcon={null}
            />
          </div>
          <div style={{ marginRight: "5%", zIndex: 9999 }}>
            <DatePicker
              onChange={(value: any) => {
                setEndDate(value);
              }}
              value={endDate}
              clearIcon={null}
            />
          </div>
        </div>

        <div className={styles.session_con}>
          <DataGrid
            rows={ sessions || [] }
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        </div>
      </div>
    </div>
  );
};


export default ChargingReports;
