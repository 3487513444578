import { useEffect, useState, FunctionComponent, useMemo } from "react";
import { useSelector } from "react-redux";
import { useSystems, useUser, IoTAPI, Signals, useActiveSignals, useGAReqResMutation } from "@ecamion/sucrose";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DisableSigModal from "./components/disableSigModal";
import EventLog from "./components/eventLogModal";
import HeadBar from "@components/headBar";
import Modal from "@mui/material/Modal";
import NotesIcon from "@mui/icons-material/Notes";
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import styles from "./globalAdjustment.module.scss";
import edit_logo from "@view/edit_logo.svg";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getDateTime, getDateTimeFromTodayHours, isNumeric, getMinutesFromSeconds } from "@utils/common";
import delete_logo from "@view/delete_logo.svg";
import { EnableSignalAPI, query } from "@services/index";
import { DefinedUseQueryResult, useQuery } from "@tanstack/react-query";
import AddSignalModal, { rechargePowerLevelList } from "./components/addSignalModal";
import axios, { AxiosError } from "axios";
import ConfirmModal from "@pages/pricingConfig/components/confirmModal";
import { toast } from "react-toastify";



const GlobalAdjustment: FunctionComponent = () => {
  const { t: translation } = useTranslation();

  const location: any | undefined = useLocation();

  const headBarTitle = location?.state?.headbarTitle || "Global Adjustment";



  // ADD SIGNAL MODAL
  const [addSigModal, setAddSigModal] = useState(0);
  const [addSigNumber, setAddSigNumber] = useState(1);
  // DISABLE SIGNAL MODAL
  const [disableSigModal, setDisableSigModal] = useState(false);
  const [disableSigNumber, setDisableSigNumber] = useState(1);
  // Signal 1
  const [sig1Events, setSig1Events] = useState<any[]>([]);
  const [sig1Pendings, setSig1Pendings] = useState<any[]>([]);
  // Signal 2
  const [sig2Events, setSig2Events] = useState<any[]>([]);
  const [sig2Pendings, setSig2Pendings] = useState<any[]>([]);


  const [errorModal, setErrorModal] = useState({ open: false, content: "" });
  const [disableModal, setDisableModal] = useState({open: false,disableFunction: () => {},});
  const [restoreChargingModal, setRestoreChargingModal] = useState(false);
  const [isEventLogModalOpen, setIsEventLogModalOpen] = useState(false);
  
  const [signalEmailAddress, setSignalEmailAddress] = useState("");
  const [emailEnable, setEmailEnable] = useState(false);
  const [currentSystemLabel, setCurrentSystemLabel] = useState("");
  // const [signalsmsNumber, setSignalsmsNumber] = useState("");
  // const [smsEnable, setSmsEnable] = useState(true);

  const [allSystems, setAllSystems] = useState<any[]>([]);
  const userSystemsIDs = Object.values(useSystems()).map((system: any) => system.id)
  const systems_new = useSystems(false,false);


  const systemStates = useSelector((state: any) => {return {id: state.system.id} });

   const _AllSignalsInfo = useAllSignalsInfo(systemStates.id, 5* 1000);
    const AllSignalsInfo = _AllSignalsInfo.data ?? {Signal1: {}, Signal2: {}};
  const mutation = useGAReqResMutation({
    actionPath: "modbus/command",
    method: "POST",
    systems: [systemStates?.id]
  });
  const [onConfirm, setOnConfirm] = useState<() => Promise<void>>(() => async () => {
   toast
    .promise(
        mutation.mutateAsync({
        data: {
            deviceName: '',
            commandType: 'issue',
            commandName: 'FacilityPowerLimit',
            payload: 1000,
        },
        }),
        {
        pending: "Sent ESS Command",
        success: {
            render: ({ data }: any) => {
            return "ESS Charging Restored Succesfully";
            },
        },
        error: {
            render: ({ data }: any) => {
            return "Command Failed";
            },
        },
        }
    )
    .then()
    .catch((err)=>{
        console.log(err)
    });
  });
  let activeEvents: DefinedUseQueryResult<any> = useActiveSignals([systemStates.id.toString()],  5* 1000)
  
    useMemo(() => {
      let systemLst = Object.values(systems_new ?? [])
      const currentSystem = systemLst.find((system)=> system.id === systemStates.id)
      setCurrentSystemLabel(currentSystem ?  currentSystem.siteName + " - " + currentSystem.fgid : "")
    
      systemLst = systemLst.filter(( systemData ) =>{
        return userSystemsIDs.includes(systemData.id) && systemData.alive
      }).map(( systemData : any ) =>{
        return {
          ...systemData,
          selected:true,
          soc: systemData.soc ?? "NOT WORKING RIGHT NOW",
          label:  systemData.siteName + " - " + systemData.fgid,
          socLabel:isNumeric(systemData.soc) ? `${(540 * (parseFloat(systemData.soc || 0) - 10)) / 100}/486 kWh` : ""
        };

      });
      setAllSystems(systemLst);
    }, [systems_new]);


  useEffect(() => {
      // new Events
      let newSig1Events: any = [];
      let newSig2Events: any = [];
      Object.values(AllSignalsInfo).forEach((sigEvent: any, index: number) => {
        if (sigEvent.IssuedParams){
            sigEvent.IssuedParams.Enabled = !sigEvent.IssuedParams?.Events.some((eventElement:any) => eventElement.Code >= 6);
            if (sigEvent.IssuedParams?.SignalType === 1 && sigEvent.IssuedParams?.Active){
              newSig1Events.push(sigEvent.IssuedParams);
            }else if (sigEvent.IssuedParams?.SignalType === 2 && sigEvent.IssuedParams?.Active){
              newSig2Events.push(sigEvent.IssuedParams);
            }
        }
      })
      if (newSig1Events.length>0){
        newSig1Events.sort((prev: any, curr: any) => {
          return new Date(curr.time).getTime() - new Date(prev.time).getTime();
        });
        setSig1Events(newSig1Events);
        setSig1Pendings([]);
      } else {
        setSig1Pendings(sig1Pendings.filter((sigEvent: any) => !sigEvent.Enabled));
        setSig1Events([]);
      }
      if (newSig2Events.length>0){
        newSig2Events.sort((prev: any, curr: any) => {
          return new Date(curr.time).getTime() - new Date(prev.time).getTime();
        });
        setSig2Events(newSig2Events);
        setSig2Pendings([]);
      } else{
        setSig2Pendings(sig2Pendings.filter((sigEvent: any) => !sigEvent.Enabled));
        setSig2Events([]);
      }

      
  }, [AllSignalsInfo]); 


  const eventRow = (signalNumber: number,sigEvent: any, index: number, pending: boolean = false)=>{
    const handleOnDisableClick = (signalNumber: number, sigEvent: any) => {
      EnableSignalAPI.post(`/system-${sigEvent.SystemId}/signal${signalNumber}`, {
        enable: false,
        uuid: sigEvent.Uuid
      })
        .then((res) => {
          if(signalNumber === 1){
            setSig1Pendings([sigEvent]);
          }else{
            setSig2Pendings([sigEvent]);
          }
        })
        .catch((err) => {
          if (axios.isAxiosError(err)) {
            const axiosError = err as AxiosError<Response>;
            const errorData:any = axiosError.response?.data;
            if (axiosError?.response?.status === 429 && errorData?.message.includes("10s")) {
              setErrorModal({
                open: true,
                content: "Please wait 10 seconds before trying again."
              });
              return;
            }
          }
          setErrorModal({
            open: true,
            content:
            "An error has occurred. \n" +
            "\nPlease try again. If the issue persists, call \n1-855-567-5853"
          });
        });
    }

    sigEvent.label = currentSystemLabel;

    if(signalNumber === 1){
      sigEvent.batteryReadyTimeLabel = pending ? getDateTime(getDateTimeFromTodayHours(sigEvent.BatteryReadyTime)) : (sigEvent.BatteryReadyTime ? getDateTime(sigEvent.BatteryReadyTime) : "-");
      sigEvent.signal1ResetTimeLabel =  pending ? getDateTime(getDateTimeFromTodayHours(sigEvent.Signal1ResetTime)) : (sigEvent.Signal1ResetTime ? getDateTime(sigEvent.Signal1ResetTime) : "-");
    }else{
      sigEvent.startTimeLabel = pending ? getDateTime(getDateTimeFromTodayHours(sigEvent.StartTime)) : (sigEvent.StartTime ? getDateTime(sigEvent.StartTime) : "-");
      sigEvent.timeOfRechargeLabel = pending ? getDateTime(getDateTimeFromTodayHours(sigEvent.TimeOfRecharge)) : (sigEvent.TimeOfRecharge ? getDateTime(sigEvent.TimeOfRecharge) : "-");
      sigEvent.essBoostEnableLabel = sigEvent.essBoostEnable ? 'ON' : 'OFF';
      sigEvent.percentEssGaLabel = sigEvent.PercentEssGa ? (sigEvent.PercentEssGa + '%') : '-';
      sigEvent.rechargePowerLevel = sigEvent.RechargePowerLevel ? rechargePowerLevelList[sigEvent.RechargePowerLevel] : '-';
      sigEvent.dischargeDurationLevel = sigEvent.DischargeDuration ? getMinutesFromSeconds(sigEvent.DischargeDuration) : "-";
    }

    return (
      <div className={styles.sig_conB_item} key={index}>
        <div className={styles.subtitleTextList}>
          <div> {sigEvent.label} </div>


          <div>
            {pending ? 
              <div className={styles.pending_status}>
                {sigEvent.enable ? "Enable Pending" : "Disable Pending"}
              </div>
            : !sigEvent.Enabled ? "Disabled"  : (
                <div className={styles.delete_con} onClick={() => {handleOnDisableClick(signalNumber, sigEvent)}}>
                  <img src={delete_logo} alt="" className={styles.img_config} />
                </div>
            )}
          </div>
        </div>
        {signalNumber === 1 ?
          (<div className={styles.sig1_conB_item_body}>
            <div className={styles.sig1_conB_item_body_part}>
              <div className={styles.sig_param}>
                <div className={[styles.paramText].join(" ")}>
                  {translation("globalAdjustment.batteryReadyTime")}:
                </div>
                <div className={[styles.valueText].join(" ")}>
                  {sigEvent.batteryReadyTimeLabel}
                </div>
              </div>
              <div className={styles.sig_param}>
                <div className={[styles.paramText].join(" ")}>
                  {translation("globalAdjustment.signal1ResetTime")}:
                </div>
                <div className={[styles.valueText].join(" ")}>
                  {sigEvent.signal1ResetTimeLabel}
                </div>
              </div>
              </div>
          </div>)

          :
           (<div className={styles.sig_conB_item_body}>
              <div className={styles.sig_conB_item_body_part}>
                <div className={styles.sig_param}>
                  <div className={[styles.paramText].join(" ")}>
                    {translation("globalAdjustment.StartTime")}:
                  </div>
                  <div className={[styles.valueText].join(" ")}>
                    {sigEvent.startTimeLabel}
                  </div>
                </div>
                <div className={styles.sig_param}>
                  <div className={[styles.paramText].join(" ")}>
                    {translation("globalAdjustment.dischargeDuration")}:
                  </div>
                  <div className={[styles.valueText].join(" ")}>
                    {sigEvent.dischargeDurationLevel}
                  </div>
                </div>
                <div className={styles.sig_param}>
                  <div className={[styles.paramText].join(" ")}>
                    {translation("globalAdjustment.timeOfRecharge")}:
                  </div>
                  <div className={[styles.valueText].join(" ")}>
                    {sigEvent.timeOfRechargeLabel}
                  </div>
                </div>
              </div>
              <div className={styles.sig_conB_item_body_part}>
                <div className={styles.sig_param}>
                  <div className={[styles.paramText].join(" ")}>
                    {translation("globalAdjustment.percentESS")}:
                  </div>
                  <div className={[styles.valueText].join(" ")}>
                    {sigEvent.percentEssGaLabel}
                  </div>
                </div>
                <div className={styles.sig_param}>
                  <div className={[styles.paramText].join(" ")}>
                    {translation("globalAdjustment.essBoost")}:
                  </div>
                  <div className={[styles.valueText].join(" ")}>
                    {sigEvent.essBoostEnableLabel}
                  </div>
                </div>
                <div className={styles.sig_param}>
                  <div className={[styles.paramText].join(" ")}>
                    {translation("globalAdjustment.rechargePowerLevel")}:
                  </div>
                  <div className={[styles.valueText].join(" ")}>
                    {sigEvent.rechargePowerLevel}
                  </div>
                </div>
              </div>
            </div> )
          }
        </div>
    )
  }

  return (
    <div className={styles.root_con}>
      <AddSignalModal
        signalNumber={addSigNumber}
        stateModal={addSigModal}
        aliveSystems ={allSystems}
        setStateModal={setAddSigModal}
        setSigPendings={addSigNumber === 1 ? setSig1Pendings : setSig2Pendings}
        signalEmailAddress={emailEnable && signalEmailAddress.length > 0 ? signalEmailAddress : ""}
        emailEnable={emailEnable}
        setErrorModal={(value: any) => {
          setErrorModal({ open: true, content: value });
        }}
      />

      <DisableSigModal
        signalNumber={disableSigNumber}
        sigDisableModal={disableSigModal}
        setSigDisableModal={setDisableSigModal}
        currentSystemLabel={currentSystemLabel}
        setSigPendings={disableSigNumber === 1 ? setSig1Pendings : setSig2Pendings}
        signalEvents={disableSigNumber === 1 ? sig1Events : sig2Events}

        setErrorModal={(value: any) => {
          setErrorModal({ open: true, content: value });
        }}
      />

      <Modal
        open={errorModal.open}
        onClose={() => {
          setErrorModal({ open: false, content: "" });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {errorModal.content}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}></Typography>
        </Box>
      </Modal>

      {/* Modal for when press the delete button */}
      <Modal
        open={disableModal.open}
        onClose={() => {
          setDisableModal({ open: false, disableFunction: () => {} });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure
          </Typography>
          <div className={styles.delete_con} onClick={disableModal.disableFunction}>
            Delete
          </div>
        </Box>
      </Modal>
       {   isEventLogModalOpen &&
        <EventLog
          isEventLogModalOpen={isEventLogModalOpen}
          currentSystemLabel={currentSystemLabel}
          eventLogCloseHandler={setIsEventLogModalOpen}
        />
       }
        <ConfirmModal
            title={"Resume ESS Recharging"}
            content={"Are you sure you want to allow the ESS to recharge?"}
            visible={restoreChargingModal}
            setVisible={setRestoreChargingModal}
            onConfirm={onConfirm}
          />

      <div className={styles.body_con}>
        <HeadBar title={headBarTitle} />
        <div style={{flexDirection:"row",display:"flex", justifyContent: "space-between"}}>
        <div style={{width:"100%", marginLeft: "5%",marginBottom: "1%",marginTop: "1%",height: "4%",}}>
          <Button className={styles.event_log__button} onClick={() => setIsEventLogModalOpen(true)}
            startIcon={<NotesIcon className={styles.event_log__button_icon} />}>
            <div className={styles.event_log__button_text}>
              {translation("globalAdjustment.eventLog")}
            </div>
          </Button>
        </div>
        <div style={{width:"100%",marginLeft: "5%",marginBottom: "1%",marginTop: "1%",height: "4%",}}>
          <Button  style={{width:"30%"}} className={styles.event_log__button}onClick={() => {
              setRestoreChargingModal(true); 
              console.log("restoreChargingModal")}}
            startIcon={<ElectricalServicesIcon className={styles.event_log__button_icon} />}>
              <div style={{color:"rgb(54, 135, 54)"}} className={styles.event_log__button_text}>
                {translation("globalAdjustment.restoreCharging")}
              </div>
          </Button>
        </div>
       </div>
        <div className={styles.main_con}>
          <div className={styles.top_con}>
            <div className={[styles.con_border, styles.sig1_con].join(" ")}>
              <div className={styles.sig1_conA}>
                <div className={styles.panelTitleText}>{translation("globalAdjustment.sig1Events")}</div>
                <div className={styles.add_sig1_event} 
                  onClick={() => {setAddSigNumber(1);setAddSigModal(1)}}>
                  {`+ ${translation("globalAdjustment.addSig1Event")}`}
                </div>
              </div>
              <div className={styles.sig1_conB}>
                <div style={{ width: "100%", height: "100%" }}>
                  {sig1Pendings.map((sig_pending: any, index: number) => {
                    return (eventRow(1,sig_pending, index,true));
                  })}
                  {sig1Events.map((sig_event: any, index: number) => {
                    return (eventRow(1,sig_event, index));
                  })}
                </div>
              </div>

              <div className={styles.disable_event1}
                onClick={() => { setDisableSigNumber(1); setDisableSigModal(true); }}>
                {translation("globalAdjustment.disableSig1Event")}
              </div>
            </div>

            <div className={[styles.con_border, styles.sig2_con].join(" ")}>
              <div className={[styles.sig2_conA].join(" ")}>
                <div className={[styles.panelTitleText].join(" ")}>
                  {translation("globalAdjustment.sig2Events")}
                </div>
                <div className={styles.add_sig2_event}
                  onClick={() => {setAddSigNumber(2);setAddSigModal(1)}}>
                  {`+ ${translation("globalAdjustment.addSig2Event")}`}
                </div>
              </div>
              <div className={[styles.sig2_conB].join(" ")}>
                <div style={{ width: "100%", height: "100%" }}>
                  {sig2Pendings.map((sig2_pending: any, index: number) => {
                    return (eventRow(2,sig2_pending, index,true));
                  })}

                  {sig2Events.map((sig_event: any, index: number) => {
                    return (eventRow(2,sig_event, index));
                  })}
                </div>
              </div>

              <div className={styles.disable_event2}
                onClick={() => {setDisableSigNumber(2);setDisableSigModal(true); }}>
                {translation("globalAdjustment.disableSig2Event")}
              </div>
            </div>
          </div>

          <div className={[styles.con_border, styles.bottom_con].join(" ")}>
            <div className={styles.bottom_conA}>
              <div className={styles.titleText}>{translation("globalAdjustment.emailSmsNotification")}</div>
              <div className={styles.edit_con}>
                <img src={edit_logo} alt="" className={styles.edit_img_config} />
                <div className={[styles.buttonText].join(" ")}>
                  {translation("globalAdjustment.editInfo")}
                </div>
              </div>
            </div>

            <div className={styles.bottom_conB}>
              <div className={styles.email}>{translation("globalAdjustment.emailAddress")}</div>
              <input
                className={styles.email_input}
                value={signalEmailAddress}
                placeholder={translation("globalAdjustment.emailInputPlaceholder")}
                onChange={(e) => {setSignalEmailAddress(e.target.value.toString());}}
              />
              <div className={styles.sms}>{translation("globalAdjustment.sendEmailNotification")}</div>
              <div>
                <Switch
                  checked={emailEnable}
                  onChange={() => {setEmailEnable(!emailEnable);}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default GlobalAdjustment;


function useAllSignalsInfo(systemId: number, interval: number = 5000): DefinedUseQueryResult<Signals, any> {
  let q = useQuery({
    queryKey: ["allSignalsInfo", systemId],
    queryFn: () => query(IoTAPI, {
        url: `/signal/system/signals`,
        params: {
          systemid: systemId,
            }
    }),
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: interval,
    initialData: []
  })
  return q
}
