export const systemStatusChargerMap = (essState: number) => {
    if (essState === 0) {
      return "Local Mode";
    } else if (essState === 1) {
      return "Off";
    } else if (essState === 2) {
      return "Idle";
    } else if (essState === 3) {
      return "Charging";
    } else if (essState === 4) {
      return "Discharging";
    } else if (essState === 5) {
      return "Faulted";
    } else {
      return "Stopped";
    }
  };


  export const systemStatusValueDecoder = (code: number) => {
    if (code === 0) {
      return "Not Connected";
    } else if (code === 1) {
      return "Not Installed";
    } else if (code === 2) {
      return "Status Unreadable";
    } else if (code === 3) {
      return "Operational";
    } else if (code === 4) {
      return "Faulted";
    } else  {
      return "Faulted";
    } 
  };

  export const systemStatusColorDecoder = (code: number) => {
    if (code === 0) {
      return "red";
    } else if (code === 1) {
      return "yellow";
    } else if (code === 2) {
      return "red";
    } else if (code === 3) {
      return "green";
    } else if (code === 4) {
      return "red";
    } else  {
      return "red";
    } 
  };