import { FunctionComponent } from "react";
import {
  systemStatusColorDecoder,
  systemStatusValueDecoder,
} from "@utils/systemStatus";
import HeadBar from "@components/headBar";
import { benderDecode } from "../../utils/subSystemStatus";
import styles from "./subSystemStatus.module.scss";
import { ESSMisc, EssString} from "@services/sse/types";
import { useFieldContext } from "@services";

const SubSystemStatus: FunctionComponent = () => {
  const fields = useFieldContext();
  const { EssString, PCCabinet, PCS, ESSMisc } = fields;

  const measures: {
    [key: string]: keyof ESSMisc;
  } = {
    "Battery Capacity Estimate": "ESSCapacityEstimate",
    "# of Complete Charge Cycles": "ESSChargeCycles",
    "# of Contactor Cycles": "ESSContractorCycles",
    "Capacitor Lifecycle Count": "ESSCapacitorCycles",
    "Battery Isolation Status (# Faults)": "ESSIsolationFaults",
  };

  return (
    <div className={styles.main_container}>
      <HeadBar title="Subsystem Status" />
      <div className={styles.body_con}>
        <div className={styles.left_con}>
          <div className={styles.left_A}>
            <div className={styles.left_A_A}>
              <div className={styles.left_A_A_item}>Battery</div>
              <div className={styles.left_C_header}>String Status</div>
            </div>
          </div>

          <div className={styles.left_B}>
            <div className={styles.left_B_column}>
              <div className={styles.left_B_column_header}>ESS String</div>
              <div className={styles.left_B_column_body}>
                {["String 1", "String 2", "String 3"].map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </div>
            </div>

            <div className={styles.left_B_column}>
              <div className={styles.left_B_column_header}>Current</div>
              <div className={styles.left_B_column_body}>
                {[1, 2, 3].map((index) => {
                  const key = `String${index}Current` as keyof EssString;
                  const value = EssString ? EssString[key] : undefined;
                  return (
                    <div key={index}>
                      {value !== undefined ? value : "--"} A
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={styles.left_B_column}>
              <div className={styles.left_B_column_header}>Voltage</div>
              <div className={styles.left_B_column_body}>
                {[1, 2, 3].map((index) => {
                  const key = `String${index}Voltage` as keyof EssString;
                  const value = EssString ? EssString[key] : undefined;
                  return (
                    <div key={index}>
                      {value !== undefined ? value : "--"} V
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={styles.left_C}>
            <div className={styles.left_C_header}>System Lifecycle</div>

            <div className={styles.left_C_body}>
              {Object.keys(measures).map((key, index) => (
                <div key={index} className={styles.left_C_row}>
                  <div className={styles.left_C_param}>{key}</div>
                  <div className={styles.left_C_value}>
                    {ESSMisc && ESSMisc[measures[key]] !== undefined
                      ? ESSMisc[measures[key]]
                      : "--"}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.left_D}></div>
        </div>

        <div className={styles.right_con}>
          <div className={styles.right_A}>
            <div className={styles.right_A_A}>
              <div className={styles.power_conversion}>Power Conversion</div>
              <div className={styles.right_C_header}>Power Core Status</div>
            </div>
          </div>

          <div className={styles.right_B}>
            <div className={styles.right_B_column}>
              <div className={styles.right_B_column_header}>Core #</div>
              <div className={styles.right_B_column_body}>
                {[
                  "Core 1",
                  "Core 2",
                  "Core 3",
                  "Core 4",
                  "Core 5",
                  "Core 6",
                  "Core 7",
                  "Core 8",
                ].map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </div>
            </div>

            <div className={styles.right_B_column}>
              <div className={styles.right_B_column_header}>Current</div>
              <div className={styles.right_B_column_body}>
                {[1, 2, 3, 4].map((index) => {
                  const key = `PowerCore-${index}`;
                  const { PCParentPresentCurrent, PCChildPresentCurrent } =
                    (fields as { [key: string]: any })[key] || {};
                  return [
                    <div key={index * 2}>
                      {PCParentPresentCurrent !== undefined
                        ? PCParentPresentCurrent
                        : "--"}{" "}
                      A
                    </div>,
                    <div key={index * 2 + 1}>
                      {PCChildPresentCurrent !== undefined
                        ? PCChildPresentCurrent
                        : "--"}{" "}
                      A
                    </div>,
                  ];
                })}
              </div>
            </div>
            <div className={styles.right_B_column}>
              <div className={styles.right_B_column_header}>Voltage</div>
              <div className={styles.right_B_column_body}>
                {[1, 2, 3, 4].map((index) => {
                  const key = `PowerCore-${index}`;
                  const { PCParentPresentVoltage, PCChildPresentVoltage } =
                    (fields as { [key: string]: any })[key] || {};
                  return [
                    <div key={index * 2}>
                      {PCParentPresentVoltage !== undefined
                        ? PCParentPresentVoltage
                        : "--"}{" "}
                      V
                    </div>,
                    <div key={index * 2 + 1}>
                      {PCChildPresentVoltage !== undefined
                        ? PCChildPresentVoltage
                        : "--"}{" "}
                      V
                    </div>,
                  ];
                })}
              </div>
            </div>

            <div className={styles.right_B_column}>
              <div className={styles.right_B_column_header}>Celcius</div>
              <div className={styles.right_B_column_body}>
                {[1, 2, 3, 4].map((index) => {
                  const key = `PowerCore-${index}`;
                  const {
                    PCParentPresentTemperature,
                    PCChildPresentTemperature,
                  } = (fields as { [key: string]: any })[key] || {};
                  return [
                    <div key={index * 2}>
                      {PCParentPresentTemperature !== undefined
                        ? PCParentPresentTemperature
                        : "--"}{" "}
                      °C
                    </div>,
                    <div key={index * 2 + 1}>
                      {PCChildPresentTemperature !== undefined
                        ? PCChildPresentTemperature
                        : "--"}{" "}
                      °C
                    </div>,
                  ];
                })}
              </div>
            </div>
          </div>

          <div className={styles.right_C}>
            <div className={styles.right_C_left}>
              <div className={styles.right_C_left_header_row}>
                <div className={styles.right_C_left_header_A}>
                  AC-DC Inverter Temperature
                </div>
                <div className={styles.right_C_left_header_B}>
                  {PCS?.Temp !== undefined ? PCS?.Temp : "--"} °C
                </div>
              </div>

              <div className={styles.right_C_left_body}>
                <div className={styles.right_C_left_row}>
                  <div className={styles.right_C_left_param}>PCS Smoke</div>
                  <div
                    className={styles.right_C_left_value}
                    style={{
                      color: `${systemStatusColorDecoder(
                        Number(PCCabinet?.PCSmokeStatus ?? 0)
                      )}`,
                    }}
                  >
                    {PCCabinet?.PCSmokeStatus
                      ? systemStatusValueDecoder(Number(PCCabinet?.PCSmokeStatus ?? 0))
                      : "--"}
                  </div>
                </div>
                <div className={styles.right_C_left_row}>
                  <div className={styles.right_C_left_param}>
                    PCS Water Level
                  </div>
                  <div
                    className={styles.right_C_left_value}
                    style={{
                      color: `${systemStatusColorDecoder(
                        Number(PCCabinet?.PCWaterStatus ?? 0)
                      )}`,
                    }}
                  >
                    {PCCabinet?.PCWaterStatus
                      ? systemStatusValueDecoder(Number(PCCabinet?.PCWaterStatus ?? 0))
                      : "--"}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.right_C_right}>
              <div className={styles.right_C_right_header}>
                Charge Dispenser Isolation
              </div>

              <div className={styles.right_C_right_body}>
                {[
                  "Dispenser 1",
                  "Dispenser 2",
                  "Dispenser 3",
                  "Dispenser 4",
                ].map((item, index) => {
                  const key = `SeccStatus-${index + 1}`;
                  const { BenderStatus } = (fields as { [key: string]: any })?.[key] ?? 0;
                  return (
                    <div key={index} className={styles.right_C_right_row}>
                      <div className={styles.right_C_right_param}>{item}</div>
                      <div className={styles.right_C_right_value}>
                        {benderDecode(Number(BenderStatus || 0))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubSystemStatus;
