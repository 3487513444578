import { FunctionComponent, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import history_logo from "@view/history.svg";
import styles from "../../sideBar.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSideBarInfo } from "@store";

const SideBarItemChargingReports: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/charging_reports") {
      dispatch(setSideBarInfo({ category: "session" }));
    }
  }, [location.pathname]);

  const sidebarState = useSelector((state: any) => {
    return {
      category: state.sidebar.category,
    };
  });

  return (
    <div
      className={styles.category}
      style={
        sidebarState.category === "session"
          ? { backgroundColor: "#eaeaea" }
          : undefined
      }
      onClick={() => {
        dispatch(setSideBarInfo({ category: "session" }));
        navigate("/charging_reports", {
          state: { headbarTitle: "Charging Reports" },
        });
      }}
    >
      <div
        className={
          sidebarState.category === "session"
            ? styles.categoryInner_selected
            : styles.categoryInnner
        }
      >
        <div className={styles.categoryImgContainer}>
          <img className={styles.categoryImg} alt={""} src={history_logo} />
        </div>
        <div className={styles.categoryWord}>Charging Reports</div>
      </div>
    </div>
  );
};

export default SideBarItemChargingReports;
