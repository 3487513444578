import {createSlice} from "@reduxjs/toolkit";
import {CostProfileSample} from "@pages/pricingConfig/components/costProfileSpec";
import { useSelector } from "react-redux";

interface CostProfileStates {
    costProfiles: typeof CostProfileSample[];
    activeCostProfile: typeof CostProfileSample | null;
}

const costProfileInitialStates: CostProfileStates = {
    costProfiles: [],
    activeCostProfile: null,
};

const costProfileSlice = createSlice({
    name: "costProfiles",
    initialState: costProfileInitialStates,
    reducers: {
        setCostProfiles: (state, action) => {
            state = {...state, costProfiles: [...action.payload]}
            return state
        },
        setActiveCostProfile: (state, action) => {
            state = {...state, activeCostProfile: action.payload}
            return state
        }
    },
});

export default costProfileSlice.reducer;

export const {setCostProfiles, setActiveCostProfile} = costProfileSlice.actions
export const useCostProfiles = () => useSelector((state: any) => state.costProfiles.costProfiles as CostProfileStates);