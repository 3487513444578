import { AlarmType, EventType, SignalType, Activity as _Activity } from "@components/activity";
import { useMemo, useState } from "react";
import { Alert,  useActiveAlerts, useActiveSignals, useHistoricalAlerts, useMetrics, useSelectedSystem, useSignals } from "@ecamion/sucrose";
import { compareDates,getDateTime, getUnixTimestamp } from "@utils/common";

export const Activity = () => {
  const systemProps  = useSelectedSystem();
  const systemId = systemProps?.id ??  0;
  const [activeMode, setActiveMode] = useState(true);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(23, 59, 59, 0))
  );

  const formatSignals = (signalArray : any []) :SignalType[] => {
    let signals  :SignalType[] = [];
    signalArray.forEach((signal : any) => {
      signal.Events.forEach((signalEvent : any)=> {
        signals.push({
          type: signal.SignalType,
          system_id: signal.SystemId,
          time: signalEvent.Time,
          enable: !!signal.Issued.Enable,
          active: signal.Active,
          params: signal.SignalType===1 ? {
            code: signalEvent.Code,
            description:signalEvent.Description,
            battery_ready_time: signal.BatteryReadyTime,
            reset_time: signal.Signal1ResetTime,
          }: {
            code: signalEvent.Code,
            description:signalEvent.Description,
            start_time: signal.StartTime,
            discharge_duration:signal.DischargeDuration,
            percent_ess_ga: signal.PercentEssGa,
            boost_enable: signal.EssBoostEnable,
            time_of_recharge: getDateTime(signal.TimeOfRecharge),
            recharge_power_level: signal.RechargePowerLevel
          }
        })
      });
    })
    return signals;
  }

  const formatEvents = (eventsArray : any []) : AlarmType[] => {
    return eventsArray.map((eventElement:Alert) => {
      return {
        time: getDateTime(new Date(eventElement.Time* 1000)),
        code: eventElement.AlarmId,
        type: eventElement.Type,
        title: eventElement.Title,
        summary: eventElement.Summary,
        severity: eventElement.SeverityId,
        severityText: eventElement.SeverityDescription,
        action: eventElement.ActionId,
        actionText: eventElement.ActionDescription,
      } ;

    }).filter((event) => event.code != 14)
  }

  const activeSignals : any = useActiveSignals([systemId.toString()], 2000)
  const activeAlerts = useActiveAlerts(systemId)


  const active: EventType[]  = useMemo(() => {
    const events = formatEvents(activeAlerts).filter((event) => event.code !== 14)
    const signals = formatSignals(Object.values(activeSignals?.data?.[systemId] ?? {})).filter((signal) =>signal.params.code !== 0);

    return [...events,...signals].sort((a: any,b: any) => {
      return compareDates(a.time,b.time) ? -1 : 1;
    })
  }, [activeAlerts,activeSignals]);

  const historicalAlerts = useHistoricalAlerts({systemId:systemId, start:getUnixTimestamp(startDate), end:getUnixTimestamp(endDate)})
  const historicalSignals = useSignals([systemId.toString()],getUnixTimestamp(startDate).toString(),getUnixTimestamp(endDate).toString(), 0)

  const historicalSorted: EventType[]  = useMemo(() => {
    const events = formatEvents(historicalAlerts.raw).filter((event) => event.code !== 14 );
    const signals = formatSignals(Object.values(historicalSignals?.data?.[systemId] ?? {})).filter((signal) =>signal.params.code !== 0);
    return [...events,...signals].sort((a: any,b: any) => {
      return compareDates(a.time,b.time) ? -1 : 1;
    })
  }, [historicalAlerts,historicalSignals?.data]);





  return (
    <_Activity
      events={{
        active,
        historical: historicalSorted || [],
      }}
      dateRange={{
        start: startDate,
        setStart: setStartDate,
        end: endDate,
        setEnd: setEndDate,
      }}
      active={activeMode}
      setActive={setActiveMode}
    />
  );
};
