import  { useState, FunctionComponent, useEffect } from "react";

const SideBarItemClock: FunctionComponent = () => {
  function getTime() {
    let currentDate = new Date();
    let localOffset = -(currentDate.getTimezoneOffset() / 60); // get the local time zone offset in hours
    let ESTOffset = -5; // set the target time zone offset (Eastern Time) in hours (-5)
    return new Date(
      currentDate.getTime() + (ESTOffset - localOffset) * 3600 * 1000
    ).toLocaleTimeString();
  }

  const [currTime, setCurrTime] = useState(getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrTime(getTime());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <div>{`${currTime} ${"EST"}`}</div>;
};

export default SideBarItemClock;
