import styles from "./event.module.scss";
import { AlarmType, EventType, SignalType } from "./types";
import critical_icon from "@view/Critical_Icon.png";
import fault_icon from "@view/Fault_Icon.png";
import help_icon from "@view/help.svg";
import info_icon from "@view/Info_Icon.png";
import warning_icon from "@view/Warning_Icon.png";
import signals_icon from "@view/Signals_Icon.png";
import signal1_icon from "@view/Signal1_Icon.png";
import signal2_icon from "@view/Signal2_Icon.png";
import { getDateTime } from "@utils/common";
import {
  faCircleExclamation,
  faCircleInfo,
  fa1,
  fa2,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface EventProps {
  onClick: () => void;
  event: EventType;
}

export interface AlarmProps {
  onClick: () => void;
  alarm: AlarmType;
}

export interface SignalEventProps {
  onClick: () => void;
  signal: SignalType;
}

export const Event = ({ onClick, event }: EventProps) => {
  const isEventAlarm = Object.keys(event).includes("code");
  return isEventAlarm ? (
    <Alarm onClick={onClick} alarm={event as AlarmType} />
  ) : (
    <SignalEvent onClick={onClick} signal={event as SignalType} />
  );
};

export const Alarm = ({ onClick, alarm }: AlarmProps) => {
  const { time, code, type, title, summary, severityText } = alarm;

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.icon}>{getIcon(type)}</div>

      <div className={styles.middle}>
        <div className={styles.title}>{title}</div>
        <div className={styles.summary}>{summary}</div>
      </div>

      <div className={styles.right}>
        <div
          style={{
            color: getSeverityColor(type),
            fontSize: "1.1vw",
            fontWeight: 600,
          }}
        >
          {type}
        </div>
        <div style={{ textAlign: "center" }}>{getDateTime(time)}</div>
      </div>
    </div>
  );
};

export const SignalEvent = ({ onClick, signal }: SignalEventProps) => {
  const { active, type, time, enable } = signal;
  const { description } = signal.params;
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.icon}>{getIcon("signal" + type)}</div>

      <div className={styles.middle}>
        <div className={styles.title}>G/A Signal {type}</div>
        <div className={styles.summary}>{description || "--"}</div>
      </div>

      <div className={styles.right}>
        <div
          style={{
            color: getSeverityColor("signal"),
            fontSize: "1.1vw",
            fontWeight: 600,
          }}
        >
          {"Signal"}
        </div>
        <div style={{ textAlign: "center" }}>{getDateTime(time)}</div>
      </div>
    </div>
  );
};

export function getIcon(type: string) {
  const style = { width: "100%", height: "100%" };
  switch (type.toLowerCase()) {
    case "critical":
    case "fault":
      return (
        <FontAwesomeIcon
          icon={faCircleExclamation}
          color={"red"}
          style={style}
        />
      );
    case "warning":
      return (
        <FontAwesomeIcon
          icon={faCircleExclamation}
          color={"orange"}
          style={style}
        />
      );
    case "info":
    case "normal":
      return (
        <FontAwesomeIcon
          icon={faCircleInfo}
          color={"rgb(48, 138, 173)"}
          style={style}
        />
      );
    case "signal1":
      return (
        <FontAwesomeIcon icon={fa1} color={"rgb(48, 138, 173)"} style={style} />
      );
    case "signal2":
      return (
        <FontAwesomeIcon icon={fa2} color={"rgb(48, 138, 173)"} style={style} />
      );

    default:
      return (
        <FontAwesomeIcon icon={faCircleInfo} color={"black"} style={style} />
      );
  }
}

export function getSeverityColor(type: string) {
  switch (type.toLowerCase()) {
    case "critical":
    case "fault":
      return "red";
    case "warning":
      return "orange";
    case "info":
    case "normal":
    case "signal":
      return "rgb(48, 138, 173)";
    default:
      return "black";
  }
}
