import { atom, selector } from "recoil";

// Create your atom here
export const energyExportPriceState = atom({
  key: 'EnergyExportPrice',
  default: parseFloat( localStorage.getItem('EnergyExportPrice') ?? "0.01" ) ,
});

export const energyDrawPriceState = atom({
  key: 'EnergyDrawPrice',
  default: parseFloat( localStorage.getItem('EnergyDrawPrice') ?? "0.01" ) ,
});