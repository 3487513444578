import { Dispatch, SetStateAction } from "react";
import _DatePicker from "react-date-picker";
import styles from "./datepicker.module.scss";

export interface DatePickerProps {
  start: Date;
  setStart: Dispatch<SetStateAction<Date>>;
  end: Date;
  setEnd: Dispatch<SetStateAction<Date>>;
}

export const DatePicker = ({
  start,
  setStart,
  end,
  setEnd,
}: DatePickerProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.start}>
        <_DatePicker
          onChange={(value: Date) => {
            setStart(value);
          }}
          value={start}
        />
      </div>
      <div className={styles.end}>
        <_DatePicker
          onChange={(value: Date) => {
            setEnd(value);
          }}
          value={end}
        />
      </div>
    </div>
  );
};

export default DatePicker;