import { useState, FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ems_logo from "@view/SideBar/ems_logo.png";
import GA_logo_selected from "@view/SideBar/GA_logo_selected.png";
import styles from "../../sideBar.module.scss";
import { setSideBarInfo } from "@store";

const SideBarItemEMS: FunctionComponent<EMSProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currSubcategory, setCurrSubCategory] = useState("");
  const location = useLocation();

  useEffect(() => {
    let isSubcategorySet = true;
    if (location.pathname === "/global_adjustment") {
      setCurrSubCategory(location.pathname.substring(1));
    } else if (location.pathname === "/metering") {
      setCurrSubCategory(location.pathname.substring(1));
    } else {
      isSubcategorySet = false;
    }
    if (isSubcategorySet) {
      dispatch(setSideBarInfo({ category: "ems" }));
    }
  }, [location.pathname]);

  const sidebarState = useSelector((state: any) => {
    return {
      category: state.sidebar.category,
    };
  });

  return sidebarState.category === "ems" ? (
    <div className={styles.categoryGA} style={{ height: "20%" }}>
      <div className={styles.categoryGAOutter}>
        <div className={styles.categoryGAInnner}>
          <div className={styles.categoryImgContainer}>
            <img
              className={styles.categoryImg}
              alt={""}
              src={GA_logo_selected}
            />
          </div>
          <div className={styles.categoryWordSelected}>Energy Management</div>
        </div>
        <div className={styles.subcategoryGAInnner}>
          {/* {localStorage.userGroups &&
          localStorage.userGroups.includes("admin") && */}
          {props.connection ? (
            <>
              <div
                className={
                  currSubcategory === "global_adjustment"
                    ? styles.subcategoryGAtext_selected
                    : styles.subcategoryGAtext
                }
                onClick={() => {
                  setCurrSubCategory("global_adjustment");
                  navigate("/global_adjustment", {
                    state: { headbarTitle: "Global Adjustment" },
                  });
                }}
              >
                Global Adjustment
              </div>
              <div
                className={
                  currSubcategory === "metering"
                    ? styles.subcategoryGAtext_selected
                    : styles.subcategoryGAtext
                }
                onClick={() => {
                  setCurrSubCategory("metering");
                  navigate("/metering", {
                    state: { headbarTitle: "Metering" },
                  });
                }}
              >
                Metering
              </div>
            </>
          ) : (
            <div
              className={
                currSubcategory === "metering"
                  ? styles.subcategoryGAtext_selected
                  : styles.subcategoryGAtext
              }
              onClick={() => {
                setCurrSubCategory("metering");
                navigate("/metering", {
                  state: { headbarTitle: "Metering" },
                });
              }}
            >
              Metering
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      className={styles.category}
      onClick={() => {
        dispatch(setSideBarInfo({ category: "ems" }));
        setCurrSubCategory("");
      }}
    >
      <div className={styles.categoryInnner}>
        <div className={styles.categoryImgContainer}>
          <img className={styles.categoryImg} alt={""} src={ems_logo} />
        </div>
        <div className={styles.categoryWord}>Energy Management</div>
      </div>
    </div>
  );
};

interface EMSProps {
  connection: boolean;
}

export default SideBarItemEMS;
