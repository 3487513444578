import React, { useEffect, useState, FunctionComponent } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BarChart: FunctionComponent<BarChartProps> = (props) => {
  const { t: translation } = useTranslation();

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: "Energy Usage Report",
        font: {
          size: 20,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
          font: {
            size: 20,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "kWh",
          font: {
            size: 20,
          },
        },
        ticks: {
          stepSize: 50,
        },
      },
    },
  };
  const [data, setData] = useState<any>({ labels: [], datasets : []});

  useEffect(() => {
    const updatedData: any = { labels: props.dates ?? [] };
    if (props.meterType === 1 && (props.dataDelivered.length > 0)) {
      updatedData["datasets"] = [
        {
          label: translation("meterSummary.energyDeliveredToChargehub"),
          borderColor: "red",
          borderWidth: 2,
          fill: false,
          data: props.dataDelivered  ?? [],
        },
        {
          label: translation("meterSummary.energyDeliveredToFacility"),
          borderColor: "blue",
          borderWidth: 2,
          fill: false,
          data: props.dataReceived,
        },
      ];
    } else if (props.meterType === 2 && (props.dataDelivered.length > 0) ) {
      updatedData["datasets"] = [
        {
          label: translation("meterSummary.energyDeliveredToTelecom"),
          backgroundColor: "#ff5b4f",
          fill: true,
          data: props.dataDelivered  ?? [],
        },
      ];
    }

   if(updatedData !== data){
     setData(updatedData);

   }
  }, [props]);

  return (
    <Bar
      type="bar"
      data={data}
      options={options}
      style={{ width: "90%", height: "90%", maxHeight: "60vh"}}
    />
  );
};

interface BarChartProps {
  dataDelivered: number[];
  dataReceived: number[];
  meterType: number;
  dates: string[];
}

export default BarChart;
