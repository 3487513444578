import {
  DependencyList,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  DefinedUseQueryResult,
  QueryKey,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { IoTAPI, query } from "@services";
import { IOT_URL } from "@constants";
import { useSystemState as useSystemId } from "@store";
import { convertToYYYYMMDD } from "@ecamion/sucrose";


// export function useTimer(ms: number) {
//   const [timer, setTimer] = useState(0);
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setTimer(timer + 1);
//     }, ms);
//     return () => clearInterval(interval);
//   }, [ms, timer]);
//   return timer;
// }



export interface SystemResponse {
  payload: any;
  message?: string;
  it: number;
  ct: number;
}

export type FieldPublishDestinations = "aws" | "local";

export interface ShadowField {
  name: string;
  value: number | string;
  group: string;
  type?: string;
  timestamp: number;
  intervals?: { [key in FieldPublishDestinations]: number };
  debugIntervals?: { [key in FieldPublishDestinations]: number };
  onChange?: FieldPublishDestinations[];
  debugOnChange?: FieldPublishDestinations[];
  register?: number;
  scale?: string;
  specFound?: boolean;
  multipleSpecs?: boolean;
}

export type GroupFields = { [fieldName: string]: ShadowField };

export type ShadowFields = { [groupName: string]: GroupFields };

export interface ShadowEvent {
  action_type: "update" | "delete";
  group_name: string;
  system_name: string;
}

export interface UpdateEvent extends ShadowEvent {
  state: { [fieldName: string]: number | string };
  timestamp: { [fieldName: string]: number };
}

// export function isValueJson(item: ShadowField | undefined) {
//   if (item === undefined) {
//     return false;
//   }
//   if (item.specFound || typeof item.value === "number") {
//     return false;
//   }
//   try {
//     JSON.parse(item.value as string);
//     return true;
//   } catch (e) {
//     return false;
//   }
// }

// export function getInterval(
//   item: ShadowField,
//   destination: FieldPublishDestinations,
//   key: "intervals" | "debugIntervals" = "intervals"
// ): number | null {
//   if (
//     item.specFound &&
//     item[key] !== undefined &&
//     item[key]![destination] !== undefined
//   ) {
//     return item[key]![destination] / 1000000000;
//   }
//   return null;
// }

// export function intervalToString(
//   item: ShadowField,
//   key: "intervals" | "debugIntervals" = "intervals"
// ): string {
//   const aws = getInterval(item, "aws", key);
//   const local = getInterval(item, "local", key);
//   return item.multipleSpecs
//     ? "Multiple Specs"
//     : item.specFound
//     ? `AWS: ${aws} | Local: ${local}`
//     : "Not Found";
// }

// export function getOnChange(
//   item: ShadowField,
//   key: "onChange" | "debugOnChange" = "onChange"
// ): FieldPublishDestinations[] {
//   if (item.specFound && item[key] !== undefined) {
//     return item[key]!;
//   }
//   return [];
// }

// export function onChangeToString(
//   item: ShadowField,
//   key: "onChange" | "debugOnChange" = "onChange"
// ): string {
//   const onChange = getOnChange(item, key);
//   return item.multipleSpecs
//     ? "Multiple Specs"
//     : item.specFound
//     ? onChange.length > 0
//       ? onChange.join(", ")
//       : "null"
//     : "Not Found";
// }

// export function getEventTimestamp(event: AnyShadowEvent) {
//   if (event.action_type === "update") {
//     const eventUpdate = event as UpdateEvent;
//     if (
//       eventUpdate.timestamp &&
//       Object.keys(eventUpdate.timestamp).length > 0
//     ) {
//       return eventUpdate.timestamp[Object.keys(eventUpdate.timestamp)[0]];
//     }
//   }
//   return null;
// }

type AnyShadowEvent = ShadowEvent | UpdateEvent;

// export function groupFieldEncode(group: string, field: string) {
//   return `${group}||${field}`;
// }

// export function groupFieldDecode(groupField: string) {
//   const split = groupField.split("||");
//   return {
//     group: split[0],
//     field: split[1],
//   };
// }

// export function findShadow(shadowFields: ShadowFields, groupFields: string) {
//   const { group, field } = groupFieldDecode(groupFields);
//   return shadowFields[group][field];
// }

interface ReqResOptions {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
  actionPath: string;
  method?: string;
  headers?: any;
  params?: any;
}

// interface ReqResMutationOptions extends ReqResOptions {}

// interface ReqResQueryOptions extends ReqResOptions {
//   payload?: any;
// }

// export function useReqResMutation({
//   actionPath,
//   method = "GET",
//   onSuccess,
//   onError,
// }: ReqResMutationOptions): [
//   UseMutationResult<SystemResponse | undefined, unknown, any, unknown>
// ] {
//   const systemId = useSystemId();
//   const q = useMutation({
//     mutationFn: async ({ data, params, headers }: any) =>
//       query(IoTAPI, {
//         url: `/action/system-${systemId}/${actionPath}`,
//         method: method,
//         data: data,
//         headers: headers,
//         params: params,
//       }),
//     retry: false,
//     cacheTime: Infinity,
//     onSuccess: onSuccess,
//     onError: onError,
//   });
//   return [q];
// }

// export function useReqResWithInitialFetch(
//   options: ReqResQueryOptions
// ): [UseQueryResult<SystemResponse>, () => boolean, QueryKey] {
//   const [reqres, shouldRefetch, queryKey] = useReqRes(options);
//   useEffect(() => {
//     if (shouldRefetch()) {
//       reqres.refetch().then();
//     }
//   }, [shouldRefetch, reqres]);
//   return [reqres, shouldRefetch, queryKey];
// }

// export function useReqRes({
//   actionPath,
//   method = "GET",
//   payload,
//   headers,
//   onSuccess,
//   onError,
//   params,
// }: ReqResQueryOptions): [
//   UseQueryResult<SystemResponse>,
//   () => boolean,
//   QueryKey
// ] {
//   const systemId = useSystemId();
//   const queryKey = useMemo(
//     () => ["reqres", systemId, actionPath, method, params, payload],
//     [systemId, actionPath, method, params, payload]
//   );
//   const q = useQuery({
//     queryKey: queryKey,
//     queryFn: async ({ signal }) =>
//       query(IoTAPI, {
//         url: `/action/system-${systemId}/${actionPath}`,
//         method: method,
//         data: payload,
//         headers: headers,
//         params: params,
//         signal,
//       }),
//     initialData: undefined,
//     refetchIntervalInBackground: false,
//     refetchOnWindowFocus: false,
//     refetchOnMount: false,
//     refetchOnReconnect: false,
//     refetchInterval: false,
//     retry: false,
//     enabled: false,
//     staleTime: Infinity,
//     cacheTime: Infinity,
//     onSuccess: onSuccess,
//     onError: onError,
//   });
//   const shouldRefetch = useCallback(() => {
//     return (
//       q.data === undefined && !q.isError && !q.isRefetching && !q.isFetching
//     );
//   }, [q]);
//   return [q, shouldRefetch, queryKey];
// }

// export function useDeviceStates({
//   realtime = false,
//   device,
// }: {
//   realtime?: boolean;
//   device: string;
// }): [DefinedUseQueryResult<any>, any] {
//   const systemId = useSystemId();
//   const queryKey = useMemo(
//     () => ["states", systemId, device],
//     [systemId, device]
//   );
//   const q: DefinedUseQueryResult<any> = useQuery<any>({
//     queryKey: queryKey,
//     queryFn: () =>
//       query(IoTAPI, {
//         url: `/action/system-${systemId}/states`,
//         params: {
//           device: device,
//         },
//       }),
//     refetchOnWindowFocus: false,
//     retry: false,
//     refetchOnMount: true,
//     refetchInterval: realtime ? 3000 : false,
//     refetchIntervalInBackground: false,
//     initialData: {},
//   });
//   const transformedStates = useMemo(() => {
//     const data: any[] = [];
//     if (q.data?.payload) {
//       Object.keys(q.data?.payload).forEach((structName: string) => {
//         Object.keys(q.data?.payload?.[structName]).forEach(
//           (groupName: string) => {
//             Object.keys(q.data?.payload?.[structName][groupName]).forEach(
//               (fieldName: string) => {
//                 if (fieldName !== "LastFetched") {
//                   const arr =
//                     q.data?.payload?.[structName][groupName][fieldName].split(
//                       ","
//                     );
//                   const d: any = {
//                     name: fieldName,
//                     group: groupName,
//                     struct: structName,
//                     value: parseFloat(arr[0]),
//                     register: parseInt(arr[1]),
//                     scale: parseFloat(arr[2]),
//                     type: arr[3],
//                     fetchedFromDevice:
//                       q.data?.payload?.[structName]?.LastFetched,
//                   };
//                   data.push(d);
//                 }
//               }
//             );
//           }
//         );
//       });
//       return data;
//     } else {
//       return [];
//     }
//   }, [q.data]);
//   return [q, transformedStates];
// }

export function useFields({
  realtime = true,
  group,
  includePublishSpec = false,
  includePublicSpec = false,
  recordsToKeep = 5,
  onDeleteUpdate = () => {},
  onChangedUpdate = () => {},
  refetchOnWindowFocus = false,
  systemId,
  fields,
}: {
  realtime?: boolean;
  group: string;
  includePublishSpec?: boolean;
  includePublicSpec?: boolean;
  recordsToKeep?: number;
  onDeleteUpdate?: (s: AnyShadowEvent) => void;
  onChangedUpdate?: (s: AnyShadowEvent) => void;
  refetchOnWindowFocus?: boolean;
  systemId?: number;
  fields?: string[];
}): [DefinedUseQueryResult<ShadowFields>, AnyShadowEvent[], QueryKey] {
  const queryClient = useQueryClient();
  const tempSystemId = useSystemId();
  const finalSystemId = systemId === undefined ? tempSystemId : systemId;
  const queryKey = useMemo(
    () => [
      "shadow",
      finalSystemId.toString(),
      group,
      fields ? fields.join(",") : "allFields",
    ],
    [finalSystemId, group, fields]
  );
  const [recordsStack, setRecordsStack] = useState<AnyShadowEvent[]>([]);
  const addRecord = useCallback(
    (record: AnyShadowEvent) => {
      setRecordsStack((prev: AnyShadowEvent[]) => {
        const newStack = [...prev];
        if (newStack.length >= recordsToKeep) {
          newStack.pop();
        }
        newStack.unshift(record);
        return newStack;
      });
    },
    [recordsToKeep]
  );
  useEffect(() => {
    setRecordsStack([]);
  }, [finalSystemId, group]);

  const q: DefinedUseQueryResult<ShadowFields> = useQuery<ShadowFields>({
    queryKey: queryKey,
    queryFn: () =>
      query(IoTAPI, {
        url: `/shadow/${finalSystemId}/${group}`,
        params: {
          includePublishSpec: includePublishSpec,
          includePublicSpec: includePublicSpec,
          fields: fields ? fields.join(",") : undefined,
        },
      }),
    enabled: finalSystemId > -1,
    refetchOnWindowFocus: refetchOnWindowFocus,
    refetchOnMount: true,
    initialData: {},
  });

  useSSE(
    realtime && finalSystemId > -1,
    queryKey,
    `${IOT_URL}/sse/shadow?stream=system-${finalSystemId}.${group}`,
    [finalSystemId, group],
    {
      event: `changed`,
      handler: (event: any) => {
        const eventData = event.data ? JSON.parse(event.data) : {};
        if (Object.keys(eventData).length > 0) {
          const patch = eventData as UpdateEvent;
          addRecord(patch);
          onChangedUpdate(patch);
          queryClient.setQueryData<ShadowFields>(
            queryKey,
            (prevData: ShadowFields | undefined) => {
              if (prevData === undefined) {
                return {};
              }
              const s = structuredClone(prevData);
              const eventGroup = patch.group_name;
              if (!s[eventGroup]) {
                s[eventGroup] = {};
              }
              if (patch.state) {
                for (const key in patch.state) {
                  const ss = s[eventGroup][key] ?? {};
                  ss.value = patch.state[key];
                  ss.name = key;
                  ss.group = eventGroup;
                  s[eventGroup][key] = ss;
                }
              }
              if (patch.timestamp) {
                for (const key in patch.timestamp) {
                  const ss = s[eventGroup][key] ?? {};
                  ss.timestamp = patch.timestamp[key];
                  ss.name = key;
                  ss.group = eventGroup;
                  s[eventGroup][key] = ss;
                }
              }
              return s;
            }
          );
        }
      },
    },
    {
      event: `deleted`,
      handler: (event: any) => {
        const eventData = event.data ? JSON.parse(event.data) : {};
        if (Object.keys(eventData).length > 0) {
          const patch = eventData as AnyShadowEvent;
          addRecord(patch);
          onDeleteUpdate(patch);
          queryClient.setQueryData<ShadowFields>(
            queryKey,
            (prevData: ShadowFields | undefined) => {
              if (prevData === undefined) {
                return {};
              }
              const s = structuredClone(prevData);
              switch (patch.action_type) {
                case "delete":
                  delete s[patch.group_name];
                  break;
                case "update":
                  if ("state" in patch && patch.state) {
                    for (const key in patch.state) {
                      delete s[patch.group_name][key];
                    }
                  }
                  break;
              }
              return s;
            }
          );
        }
      },
    }
  );
  return [q, recordsStack, queryKey];
}

interface EventHandler {
  event: string;
  handler: (event: any) => void;
}

interface EventSourceBundle {
  eventSource: EventSource;
  subscribers: number;
}

const eventSources = new Map<string, EventSourceBundle>();

function newEventSource(
  queryKey: string[],
  url: string,
  ...handlers: EventHandler[]
) {
  const key = queryKeyToString(queryKey);
  const events = handlers.map((h) => h.event);
  if (!eventSources.has(key)) {
    const eventSource = new EventSource(url, { withCredentials: true });
    handlers.forEach((handler) => {
      eventSource.addEventListener(handler.event, handler.handler);
    });
    // console.log("new event source", key, events);
    eventSources.set(key, {
      eventSource: eventSource,
      subscribers: 1,
    });
  } else {
    const bundle = eventSources.get(key);
    if (bundle) {
      bundle.subscribers++;
      // console.log(
      //   "existing event source + 1 subscriber",
      //   key,
      //   events,
      //   bundle.subscribers
      // );
    }
  }
  return eventSources.get(key);
}

function destroyEventSource(queryKey: string[]) {
  const key = queryKeyToString(queryKey);
  if (eventSources.has(key)) {
    const bundle = eventSources.get(key)!;
    bundle.subscribers--;
    if (bundle.subscribers <= 0) {
      // console.log("destroy event source", key);
      bundle.eventSource.close();
      eventSources.delete(key);
    }
  }
}

function queryKeyToString(queryKey: string[]) {
  return queryKey.join("-");
}

export function useSSE(
  enable: boolean,
  queryKey: string[],
  url: string,
  dependencies: DependencyList,
  ...handlers: EventHandler[]
) {
  useEffect(() => {
    if (enable) {
      newEventSource(queryKey, url, ...handlers);
    }
    return () => {
      destroyEventSource(queryKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, enable]);
}

