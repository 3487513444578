export const CHARGE_HUB_API_BASE_URL = process.env.REACT_APP_CHARGEHUB_URL;
export const IOT_URL = process.env.REACT_APP_IOT_URL;
export const getSignOutUrl = () => {
  return (
    `https://${process.env.REACT_APP_OAUTH2_SIGN_OUT_HOSTNAME}/oauth2/sign_out?rd=` +
    encodeURIComponent(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${window.location.origin}`
    )
  );
};

export const getSignInUrl = () => {
  return (
    `https://${process.env.REACT_APP_OAUTH2_SIGN_OUT_HOSTNAME}/oauth2/start?rd=` +
    encodeURIComponent(window.location.href)
  );
};
export const signOut = () => {
  window.location.href = getSignOutUrl();
};

export const signIn = () => {
  window.location.href = getSignInUrl();
};
