import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext } from "react";
import { GroupFields, ShadowFields, useFields } from "./sse";
import {
  AlarmCodes,
  AlertType,
  CostProfile,
  ESSMisc,
  EssString,
  EssConfig,
  EssControl,
  EssSensor,
  EssStatus,
  GroupFieldValues,
  Info,
  M1,
  M2,
  M3,
  PCCabinet,
  PCS,
  Signal1,
  Signal1Response,
  Signal2,
  Signal2Response,
  StringModule,
  StringModules,
  SeccMeter,
  SeccStatus,
  SeccSession,
  CCS,
  Chademo,
  PowerCore,
  EMS
} from "./types";

export const FieldContext = React.createContext<GroupFieldValues>({});
export const useFieldContext = () => useContext(FieldContext);

export const useStringModuleContext = (string: number, module: number) => {
  const fields = useFieldContext();

  if (string < 1 || string > 3 || module < 1 || module > 16) return undefined;

  return fields[
    `String${string}Module${module}` as keyof StringModules
  ] as StringModule;
};

function useAllFields() {
  const [q, _, queryKey] = useFields({
    realtime: true,
    group: "all",
  });

  const output = {
    fields: {} as GroupFieldValues,
    queryKey,
  };


  if (!q.isSuccess) return output;
  Object.keys(q.data).forEach((key) =>
    appendToGroupField(key, output.fields, q.data)
  );

  if (!output.fields.Alert) return output;

  return output;
}

export function FieldProvider({ children }: { children: React.ReactNode }) {
  const { fields } = useAllFields();
  return (
    <FieldContext.Provider value={fields}>{children}</FieldContext.Provider>
  );
}

// export function convertField<T extends Omit<GroupFieldValues[keyof GroupFieldValues], "AlertType[]">,U extends keyof T>(group: T, key: U, defaultValue?: T[U]) {
//   const val = group && group[key];
//   if (val === undefined) return defaultValue;
//   return val;
// }

function getJsonFields<T>(records: GroupFields) {
  return Object.keys(records).map((key) => {
    return JSON.parse(records[key].value as string) as T;
  });
}

export function getFieldAsType<T>(records: GroupFields) {
  const output: { [key: string]: any } = {};
  Object.keys(records).forEach((key) => {
    output[key] = records[key].value;
  });
  return output as Partial<T>;
}

function appendToGroupField(
  key: string,
  fields: GroupFieldValues,
  data: ShadowFields
) {
  const re = /String\d+Module\d+/;
  if (key.search(re) !== -1) {
    fields[key as keyof StringModules] = getFieldAsType<StringModule>(
      data[key]
    );
    return;
  }

  switch (key) {
    case "Alert":
      (fields as { [key: string]: any })[key] = getJsonFields(data[key]);
      break;
    case "CostProfile":
      (fields as { [key: string]: any })[key] = getFieldAsType<CostProfile>(data[key]);
      break;
    case "EssConfig":
      (fields as { [key: string]: any })[key] = getFieldAsType<EssConfig>(data[key]);
      break;
    case "EssControl":
      (fields as { [key: string]: any })[key] = getFieldAsType<EssControl>(data[key]);
      break;
    case "EssSensor":
      (fields as { [key: string]: any })[key] = getFieldAsType<EssSensor>(data[key]);
      break;
    case "EssStatus":
      (fields as { [key: string]: any })[key] = getFieldAsType<EssStatus>(data[key]);
      break;
    case "EssString":
      (fields as { [key: string]: any })[key] = getFieldAsType<EssString>(data[key]);
      break;
    case "ESSMisc":
      (fields as { [key: string]: any })[key] = getFieldAsType<ESSMisc>(data[key]);
      break;
    case "Info":
      (fields as { [key: string]: any })[key] = getFieldAsType<Info>(data[key]);
      break;
    case "M1":
      (fields as { [key: string]: any })[key] = getFieldAsType<M1>(data[key]);
      break;
    case "M2":
      (fields as { [key: string]: any })[key] = getFieldAsType<M2>(data[key]);
      break;
    case "M3":
      (fields as { [key: string]: any })[key] = getFieldAsType<M3>(data[key]);
      break;
    case "PCCabinet":
      (fields as { [key: string]: any })[key] = getFieldAsType<PCCabinet>(data[key]);
      break;
    case "PCS":
      (fields as { [key: string]: any })[key] = getFieldAsType<PCS>(data[key]);
      break;
    case "SeccStatus-1":
    case "SeccStatus-2":
    case "SeccStatus-3":
    case "SeccStatus-4":
      (fields as { [key: string]: any })[key] = getFieldAsType<SeccStatus>(data[key]);
      break;
    case "SeccMeter-1":
    case "SeccMeter-2":
    case "SeccMeter-3":
    case "SeccMeter-4":
      (fields as { [key: string]: any })[key] = getFieldAsType<SeccMeter>(data[key]);
      break;
    case "SeccSession-1":
    case "SeccSession-2":
    case "SeccSession-3":
    case "SeccSession-4":
      (fields as { [key: string]: any })[key] = getFieldAsType<SeccSession>(data[key]);
      break;
    case "CCS-1":
    case "CCS-2":
    case "CCS-3":
    case "CCS-4":
      (fields as { [key: string]: any })[key] = getFieldAsType<CCS>(data[key]);
      break;
    case "Chademo-1":
    case "Chademo-2":
    case "Chademo-3":
    case "Chademo-4":
      (fields as { [key: string]: any })[key] = getFieldAsType<Chademo>(data[key]);
      break;
    case "PowerCore-1":
    case "PowerCore-2":
    case "PowerCore-3":
    case "PowerCore-4":
      (fields as { [key: string]: any })[key] = getFieldAsType<PowerCore>(data[key]);
      break;
    case "Signal1":
      (fields as { [key: string]: any })[key] = getFieldAsType<Signal1>(data[key]);
      break;
    case "Signal1Response":
      (fields as { [key: string]: any })[key] = getFieldAsType<Signal1Response>(data[key]);
      break;
    case "Signal2":
      (fields as { [key: string]: any })[key] = getFieldAsType<Signal2>(data[key]);
      break;
    case "Signal2Response":
      (fields as { [key: string]: any })[key] = getFieldAsType<Signal2Response>(data[key]);
      break;
    case "ModbusStatus":
      (fields as { [key: string]: any })[key] = getJsonFields(data[key]);
      break;
    case "EMS":
      (fields as { [key: string]: any })[key] = getFieldAsType<EMS>(data[key]);
      break;
    default:
      console.log("Unknown field type: " + key);
      break;
  }
}

function convertAlarmCode({
  id,
  action_id,
  actionIdByActionId,
  fault_description,
  severity_id,
  severityIdBySeverityId,
  summary,
  title,
  type,
}: any) {
  return {
    id,
    action_id,
    action_description: actionIdByActionId.action_description,
    fault_description,
    severity_id,
    severity_description: severityIdBySeverityId.severity_description,
    summary,
    title,
    type,
  } as AlarmCodes;
}
