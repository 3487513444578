import EmsModal from "@components/modal/emsModal";
import HeadBar from "@components/headBar";
import { useState, FunctionComponent } from "react";
import styles from "./userProfile.module.scss";
import { useLocation } from "react-router-dom";
import { useUser } from "@ecamion/sucrose";
import { User } from "@models/user";

const UserProfile: FunctionComponent = () => {
  const [modalMode, setModalMode] = useState<number>(0);

  const location: any = useLocation();

  const headBarTitle = location.state?.headbarTitle;

  const userInfo : User | undefined  = useUser();

  return (
    <div className={styles.root_container}>
      <EmsModal open={modalMode}>
        {modalMode ? (
          <div className={styles.modal_con}>
            <div className={styles.headerA}>Request Permission Changes</div>
            <div className={[styles.headerD, styles.modalMiddle].join(" ")}>
              Upon requesting changes, an administrator will be notified to
              approve of your requested change in privileges
            </div>
            <div className={styles.modal_button_con}>
              <div
                onClick={() => {
                  setModalMode(0);
                }}
                className={[
                  styles.headerD,
                  styles.button_border,
                  styles.modal_sent,
                ].join(" ")}
              >
                Cancel
              </div>
              <div
                className={[
                  styles.headerD,
                  styles.button_border,
                  styles.modal_sent,
                  styles.send_request,
                ].join(" ")}
                onClick={() => {
                  setModalMode(2);
                }}
              >
                Send Request
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.modal_con}>
            <div className={[styles.headerA].join(" ")}>Request Sent</div>
            <div className={[styles.headerD, styles.modalMiddle].join(" ")}>
              You will be notified via email when your requested change in
              privileges has been approved.
            </div>
            <div
              className={[styles.headerD, styles.button_border, styles.ok].join(
                " "
              )}
              onClick={() => {
                setModalMode(0);
              }}
            >
              OK
            </div>
          </div>
        )}
      </EmsModal>

      <div className={styles.right_container}>
        <HeadBar title={headBarTitle} />
        <div className={styles.main_container}>
          <div className={styles.main_con_left}>
            
            <div className={[styles.bottom_left, styles.con_border].join(" ")}>
              

              <div className={[styles.bottom_leftA].join(" ")}>
                <div className={[styles.headerA].join(" ")}>Account</div>
              </div>

              <div className={[styles.bottom_leftB].join(" ")}>
                <div
                  className={[styles.bottom_leftB_param, styles.headerC].join(
                    " "
                  )}
                >
                  Name
                </div>
                <div
                  className={[styles.bottom_leftB_value, styles.headerD].join(
                    " "
                  )}
                >
                  {userInfo?.name || "Unknown"}
                </div>
              </div>
              <div className={[styles.bottom_leftB].join(" ")}>
                <div
                  className={[styles.bottom_leftB_param, styles.headerC].join(
                    " "
                  )}
                >
                  Email Address
                </div>
                <div
                  className={[styles.bottom_leftB_value, styles.headerD].join(
                    " "
                  )}
                >
                  {userInfo?.email || "Unknown"}
                </div>
              </div>
              <div className={[styles.bottom_leftB_last].join(" ")}>
                <div
                  className={[styles.bottom_leftB_param, styles.headerC].join(
                    " "
                  )}
                >
                  Profile image
                </div>
                <div
                  className={[styles.bottom_leftB_value, styles.headerD].join(
                    " "
                  )}
                >
                  <img alt={''} src={userInfo?.picture} className={styles.profile_image}/>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.main_con_right}>
            <div className={[styles.top_right, styles.con_border].join(" ")}>
              <div className={[styles.headerA, styles.permission].join(" ")}>
                Permissions
              </div>

              <div className={styles.user_group_con}>
                <div>User Role:</div>

                <div className={styles.user_group_value}>
                  <ul>
                  {userInfo?.roles?.map((role: any, index: number) => <li key={index} >{role}</li>)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserProfile;
