import { FunctionComponent } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import CostProfile from "@pages/pricingConfig/components/costProfile";
import CostProfileTable from "@pages/pricingConfig/components/costProfileTable";
import { useEffect } from "react";
import { SnackbarProvider } from "notistack";
import { setActiveCostProfile } from "@store";
import {
  updateActiveCostProfile,
  updateCostProfiles,
} from "@utils/pricingConfig";
import HeadBar from "@components/headBar";
import { useLocation } from "react-router-dom";

const PricingConfig: FunctionComponent = () => {
  const systemId = useSelector((state: any) => state.system.id);

  const activeCostProfile = useSelector(
    (state: any) => state.costProfiles.activeCostProfile
  );

  const dispatch = useDispatch();

  useEffect(() => {
    updateCostProfiles();
    updateActiveCostProfile(systemId);
  }, [systemId]);

  const location: any | undefined = useLocation();

  const headBarTitle = location?.state?.headbarTitle || "Pricing Configuration";

  return (
    <div className={styles.main_container}>
      <HeadBar title={headBarTitle} />
      <SnackbarProvider maxSnack={10}>
        <div className={styles.root}>
          <CostProfile
            showEditButton={true}
            showActionButton={true}
            costProfile={activeCostProfile}
            setCostProfile={(s: any) => {
              dispatch(setActiveCostProfile(s));
            }}
          />
          <div className={styles.table}>
            <CostProfileTable />
          </div>
        </div>
      </SnackbarProvider>
    </div>
  );
};

export default PricingConfig;
