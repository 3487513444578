import React, { useState } from "react";

import Jule_logo from "@view/chargerDispenser.png";
import { chargerDecoder } from "@utils/julePerformance";
import classes from "./julePerformance.module.scss";
import ChargerDetailsModal from "./chargerDetailsModal";

const JulePerformance = ({ juleStats }: JulePerformanceProps) => {
  const sorted = Object.keys(juleStats).sort((a, b) => Number(a) - Number(b));

  return (
    <div className={classes.root_container}>
      <div className={classes.title} style={{ marginTop: "3%" }}>
        Individual Performance
      </div>

      <div className={classes.scroll_con}>
        <div style={{ width: "100%", height: "100%" }}>
          {sorted.map((key: string, index) => (
            <SingleJule key={index} {...juleStats[Number(key)]} />
          ))}
        </div>
      </div>
    </div>
  );
};

function SingleJule({
  charger_id,
  status,
  power,
  total_energy_dispensed,
}: SingleJuleProps) {
  const [showCargerDetails, setShowCargerDetails] = useState<boolean>(false);

  return (
    <div className={classes.scroll_item}>


      <ChargerDetailsModal 
      showCargerDetails={showCargerDetails} 
      setShowCargerDetails={setShowCargerDetails} 
      charger_id={charger_id} /> 

      <div className={classes.con_A}>
        <div style={{ height: "30%", color: "#6C757D" }}>Jule {charger_id}</div>
        <div style={{ height: "70%" }}>
          <img
            src={Jule_logo}
            alt={""}
            style={{ maxWidth: "100%", height: "100%" }}
          />
        </div>
      </div>

      <div className={classes.con_B}>
        <div style={{ color: "#6C757D" }}>
          {`Charger Id: ${charger_id || "--"}`}
        </div>

        <div className={classes.status_subcontainerA_B}>
          <div className={classes.statusItem_container}>
            <div className={classes.statusText}>Status:</div>

            <div className={classes.statusIndicator}>
              {chargerDecoder(status)}
            </div>
          </div>

          <div className={classes.statusItem_container}>
            <div className={classes.statusText}>Power:</div>

            <div className={classes.statusIndicator}>
              {`${power / 1000 || "--"} kW`}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.con_C}>
        <div
          style={{
            color: "#6C757D",
            fontWeight: 400,
            textAlign: "center",
          }}
        >
          Daily kWh Usage
        </div>
        <div
          style={{
            color: "#636465",
            fontSize: "1.5vw",
            fontWeight: 600,
            marginTop: "10%",
          }}
        >
          {`${total_energy_dispensed || "--"} kWh`}

        </div>
        <div className={classes.details_button} onClick={()=>{setShowCargerDetails(true);}}>Details</div>
      </div>
    </div>
  );
}

export interface JulePerformanceProps {
  juleStats: {
    [key: number]: SingleJuleProps;
  };
}

export interface SingleJuleProps {
  charger_id: number;
  status: number;
  power: number;
  total_energy_dispensed: number;
}

export default JulePerformance;
