import React, { FunctionComponent } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ContactModal: FunctionComponent<ContactProps> = (props) => {
  return (
    <Modal
      open={props.isContactOpen}
      onClose={() => {
        props.setIsContactOpen(false);
      }}
      // sx={{width:"50%"}}
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, fontSize: "1.4vw", fontWeight: 700, color: "#6c757e" }}
        >
          Facility Contacts
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, fontSize: "1.1vw", fontWeight: 600, color: "#6c757e" }}
        >
          Jule Contact Center
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, fontSize: "1vw", fontWeight: 500, color: "#6c757e" }}
        >
          Phone: 1-855-567-5853
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, fontSize: "1.1vw", fontWeight: 600, color: "#6c757e" }}
        >
          {props.contactCenterInfo.site_name}  - Facility Manager
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, fontSize: "1vw", fontWeight: 500, color: "#6c757e" }}
        >
          Phone:{props.contactCenterInfo.contact_center_phone}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, fontSize: "1vw", fontWeight: 500, color: "#6c757e" }}
        >
          Email: {props.contactCenterInfo.contact_center_email}
        </Typography>
      </Box>
    </Modal>
  );
};

interface ContactProps {
  isContactOpen: boolean;
  setIsContactOpen: Function;
  contactCenterInfo: any;
}

export default ContactModal;
