import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

export interface UserState {
  name: string;
  nickname?: string;
  email?: string;
  picture?: string;
  permissions?: string[];
  roles?: string[];
  sub?: string;
  userMetaData?: {
    [key: string]: any;
  };
  appMetaData?: {
    systems?: string[];
  };
}

export const userInitialState: UserState = {
  name: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    setUser: (state, action) => {
      state = { ...userInitialState, ...action.payload };
      return state;
    },
    resetUser: (state) => {
      return { ...userInitialState };
    },
  },
});

export const { setUser, resetUser } = userSlice.actions;
export const useUser = () =>
  useSelector((state: any) => state.user as UserState);

export default userSlice.reducer;
