import { useState, useEffect, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import battery_logo from "@view/SideBar/battery.png";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../../sideBar.module.scss";
import { setSideBarInfo } from "@store";

const SideBarItemBMS: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currSubcategory, setCurrSubCategory] = useState("");
  const location = useLocation();

  useEffect(() => {
    let isSubCategorySet = true;
    if (location.pathname === "/system_status") {
      setCurrSubCategory(location.pathname.substring(1));
    } else if (location.pathname === "/temp_history") {
      setCurrSubCategory(location.pathname.substring(1));
    } else if (location.pathname === "/subsystem_status") {
      setCurrSubCategory(location.pathname.substring(1));
    } else if (location.pathname === "/cell_temperature") {
      setCurrSubCategory(location.pathname.substring(1));
    } else {
      isSubCategorySet = false;
    }
    if (isSubCategorySet) {
      dispatch(setSideBarInfo({ category: "bms" }));
    }
  }, [dispatch, location.pathname]);

  const sidebarState = useSelector((state: any) => {
    return {
      category: state.sidebar.category,
    };
  });

  return sidebarState.category === "bms" ? (
    <div className={styles.categoryGA}>
      <div className={styles.categoryGAOutter}>
        <div className={styles.categoryGAInnner}>
          <div className={styles.categoryImgContainer}>
            <img className={styles.categoryImg} alt={""} src={battery_logo} />
          </div>
          <div className={styles.categoryWordSelected}>Battery Management</div>
        </div>
        <div className={styles.subcategoryGAInnner}>
          <div
            className={
              currSubcategory === "system_status"
                ? styles.subcategoryGAtext_selected
                : styles.subcategoryGAtext
            }
            onClick={() => {
              setCurrSubCategory("system_status");
              navigate("/system_status", {
                state: { headbarTitle: "System Status" },
              });
            }}
          >
            System Status
          </div>

          <div
            className={
              currSubcategory === "temp_history"
                ? styles.subcategoryGAtext_selected
                : styles.subcategoryGAtext
            }
            onClick={() => {
              setCurrSubCategory("temp_history");
              navigate("/temp_history", {
                state: { headbarTitle: "Temperature History" },
              });
            }}
          >
            Temperature History
          </div>
          <div
            className={
              currSubcategory === "subsystem_status"
                ? styles.subcategoryGAtext_selected
                : styles.subcategoryGAtext
            }
            onClick={() => {
              setCurrSubCategory("subsystem_status");
              navigate("/subsystem_status", {
                state: { headbarTitle: "Subsystem Status" },
              });
            }}
          >
            Subsystem Status
          </div>

          <div
            className={
              currSubcategory === "cell_temperature"
                ? styles.subcategoryGAtext_selected
                : styles.subcategoryGAtext
            }
            onClick={() => {
              setCurrSubCategory("cell_temperature");
              navigate("/cell_temperature", {
                state: { headbarTitle: "Cell Temperature" },
              });
            }}
          >
            Cell Temperature
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={styles.category}
      onClick={() => {
        dispatch(setSideBarInfo({ category: "bms" }));
      }}
    >
      <div className={styles.categoryInnner}>
        <div className={styles.categoryImgContainer}>
          <img className={styles.categoryImg} alt={""} src={battery_logo} />
        </div>
        <div className={styles.categoryWord}>Battery Management</div>
      </div>
    </div>
  );
};

export default SideBarItemBMS;
