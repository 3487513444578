import {  useState, FunctionComponent, Fragment, useMemo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EmsModal from "@components/modal/emsModal";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import classes from "../globalAdjustment.module.scss";
import infoIcon from "@view/Info_Icon.png";
import { countSeconds} from "@utils/globalAdjustment";
import { useTranslation } from "react-i18next";
import styles from "../globalAdjustment.module.scss";
import { System } from "@ecamion/sucrose";
import { Switch } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { EnableSignalAPI } from "@services/index";
import { getDateTimeFromTodayHours } from "@utils/common";
import dayjs from "dayjs";
import axios, { AxiosError } from "axios";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

// currently shared by both signal 1 and signal 2. Needs to be cleaned up before or after use
const AddSignalModal: FunctionComponent<AddSigEventProps> = (props) => {
    const { t: translation } = useTranslation();
    const [selectAll, setSelectAll] = useState(true);
    const [warning, setWarning] = useState(false);
    const [info, setInfo] = useState({ open: false, type: "" });
    // let sigDuplicate = useRef<any>([]);
    const [aliveSystemsData, setSystemsData] = useState<InteractiveSystem[]>([]);
    const [selectedSystems, setSelectedSystems] = useState<InteractiveSystem[]>([])
    const configInitialValue = {
        BatteryReadyTime: dayjs().format("HH:mm"),
        Signal1ResetTime: dayjs().add(3, "hour").format("HH:mm"),
        StartTime: dayjs().format("HH:mm"),
        DischargeDuration: 120,
        PercentEssGa: 50,
        essBoostEnable: false,
        TimeOfRecharge: dayjs().add(3, "hour").format("HH:mm"),
        RechargePowerLevel: 5,
    }
    const [sigConfig, setSigConfig] = useState(configInitialValue);
    useMemo(() => {
        const newAliveSystems = [...props.aliveSystems]
        .filter(( systemData ) =>{return systemData.fgid})
        setSystemsData( newAliveSystems)
    }, [])

    const requestEnableSignal = (item: any) => {
        let reqBody : any = props.signalNumber === 1 ? {
            battery_ready_time: item.BatteryReadyTime,
            signal1_reset_time: item.Signal1ResetTime,
            enable: item.enable,
        } : {
            enable: true,
            start_time: item.StartTime,
            time_of_recharge: item.TimeOfRecharge,
            discharge_duration_minutes: item.DischargeDuration,
            percent_ess_ga: item.PercentEssGa,
            ess_boost_enable: item.essBoostEnable,
            recharge_power_level: item.RechargePowerLevel,
        }

        if(props.signalEmailAddress && props.signalEmailAddress.length > 0 && props.emailEnable){
            reqBody["signal_email_address"] = props.signalEmailAddress;
        }
        EnableSignalAPI.post(`/system-${item.id}/signal${props.signalNumber}`, reqBody)
        .then((res) => {
            props.setSigPendings((sigPendings: any) => [...sigPendings,item]);
            dismissModal();
        })
        .catch((err) => {
            
            if (axios.isAxiosError(err)) {
                const axiosError = err as AxiosError<Response>;
    
                if (axiosError?.response?.status === 400 && (axiosError?.response?.data as any)?.message?.includes("StartTime + DischargeDuration must be before TimeOfRecharge")) {
                    props.setErrorModal("Start Time + Discharge Duration must be before Time Of Recharge");
                    return;
                }
            }
            props.setErrorModal(
            "An error has occurred. \n" +
            "\nPlease try again. If the issue persists, call \n1-855-567-5853"
            );
            console.error(err.response.request.response);
        })
    }
    const dismissModal = () =>{
        setSelectAll(true);
        props.setStateModal(0);
        const tempSystems = aliveSystemsData.map((system)=>{
            system.selected = true ;
            return system;
        })
        setSystemsData(tempSystems)
        setSigConfig(configInitialValue)
    }

    const initiate = () => {
        // TIME VALIDATIONS
        if (props.signalNumber === 1 ) {

            let StartTime = countSeconds(sigConfig.BatteryReadyTime);
            let end_time = countSeconds(sigConfig.Signal1ResetTime);
            if(StartTime >= end_time){
                alert(translation("globalAdjustment.batteryReadyTimeAlertMsg"));
                return null;
            }
        }else{
            let StartTime = countSeconds(sigConfig.StartTime);
            let end_time = countSeconds(sigConfig.TimeOfRecharge);
            if(StartTime >= end_time){
                alert(translation("globalAdjustment.timeOfRechargeAlertMsg"));
                return null;
            }
        }

        setSelectedSystems(aliveSystemsData
            .filter((itemFiltered) => itemFiltered.selected === true)
            .map((system) => {
                let additionalFields : any = {};
                if(props.signalNumber === 1) {
                    additionalFields.BatteryReadyTime = dayjs(sigConfig.BatteryReadyTime).unix();
                    additionalFields.Signal1ResetTime =  dayjs(sigConfig.Signal1ResetTime).unix();
                }else{
                    additionalFields.StartTime = dayjs(sigConfig.StartTime).unix();
                    additionalFields.DischargeDuration = sigConfig.DischargeDuration;
                    additionalFields.PercentEssGa = sigConfig.PercentEssGa;
                    additionalFields.essBoostEnable = sigConfig.essBoostEnable;
                    additionalFields.TimeOfRecharge = dayjs(sigConfig.TimeOfRecharge).unix();
                    additionalFields.RechargePowerLevel = sigConfig.RechargePowerLevel;
                }
            return {
                system_id: system.id,
                enable: system.selected,
                status: "Activated",
                ...system,
                ...additionalFields
            };
        }));
        props.setStateModal(5);
 
    }
    const finalActions = (warningAccepted: boolean = false, props: any) => {
        if( props.signalNumber === 1 && !warningAccepted ){
            const currentDate: any = new Date().getTime();
            const BatteryReadyTime_date = getDateTimeFromTodayHours(sigConfig.BatteryReadyTime).getTime();
            if ( BatteryReadyTime_date - currentDate < (3 * 60 * 60 * 1000) ) {
                setWarning(true);
                return null;
            }
        }
        selectedSystems.forEach((item: any) => {
            if (props.signalNumber === 1) {
                item.BatteryReadyTime = dayjs(getDateTimeFromTodayHours(sigConfig.BatteryReadyTime)).unix();
                item.Signal1ResetTime = dayjs(getDateTimeFromTodayHours(sigConfig.Signal1ResetTime)).unix();
            }else{
            item.StartTime = dayjs(getDateTimeFromTodayHours(sigConfig.StartTime)).unix();  
            item.TimeOfRecharge = dayjs(getDateTimeFromTodayHours(sigConfig.TimeOfRecharge)).unix();
            item.DischargeDuration = sigConfig.DischargeDuration;
            item.PercentEssGa = sigConfig.PercentEssGa;
            item.essBoostEnable = sigConfig.essBoostEnable;
            item.RechargePowerLevel = sigConfig.RechargePowerLevel;
            }
            
            requestEnableSignal(item);

        });

    }
    return (
        <Fragment> 
            {/* WARNING MODAL FOR SIGNAL 1 RESET TIME LESSER THAN 3H */}
            <Modal open={warning} onClose={() => {setWarning(false);}}>
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Are you sure?
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Battery Reset Time of less than 3 hours will severely limit EV
                    Charging pedestal performance and the customer experience
                </Typography>
                <Box>
                    <Button onClick={() => { setWarning(false);props.setStateModal(2);}}>
                        {translation("buttonLabel.cancel")}
                    </Button>
                    <Button onClick={() => { setWarning(false);finalActions(true, props);}}>
                        {translation("buttonLabel.confirm")}
                    </Button>
                </Box>
                </Box>
            </Modal>

            {/* INFORMATION MODAL */}
            <Modal open={info.open} onClose={() => {setInfo({ open: false, type: "" })}}>
                <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {info.type === "charge"
                        ? "Battery Ready Time indicates to the system a time at which the battery should be fully charged. The default time to charge the ESS from 0% state of charge is approximately 2.5-3.0 hours."
                        : info.type === "discharge"
                        ? "The time that the battery will terminate energy curtailment and resume normal operational conditions if a Signal 2 discharge has not been initiated"
                        : "Enable the reserve capacity of the ESS, consisting of 54kWh."}
                    </Typography>
                </Box>
            </Modal>

            {/* MAIN OPERATIONS MODAL */}
            <EmsModal open={props.stateModal} modal_width={"40%"} modal_height={"auto"}>
                    <div className={classes.modal_con}>
                        <div className={classes.modal_header_row}>
                            <div className={styles.sig_title}>{translation(`globalAdjustment.newSig${props.signalNumber}Event`)}</div>
                            <div className={styles.event_log__close_button}>
                                <IconButton aria-label="close" onClick={dismissModal}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        {props.stateModal === 1 ? (
                            /* *****************************MODE 1 - LIST ALL ACTIVE SYSTEMS******************************/
                            <div>
                                <div className={classes.modal_subheader}>{translation("globalAdjustment.selectAffectedSites")}:</div>
                                <div className={classes.modal_body}>
                                    <div className={classes.selectAllCon}>
                                        <div>{translation("globalAdjustment.selectAll")}</div>
                                        <Checkbox  checked={selectAll}
                                            onChange={() => {
                                                const tempSystems = aliveSystemsData.map((system)=>{
                                                    system.selected = !selectAll ;
                                                    return system;
                                                })
                                                setSystemsData(tempSystems)
                                                setSelectAll(!selectAll);                                                
                                            }}
                                        />
                                    </div>
                                    <div>
                                        {(aliveSystemsData).map((item : InteractiveSystem, index : number) => {
                                            return (
                                                <div className={[classes.modal_row, classes.paramText2].join(" ")} key={index}>
                                                    <div>{item.label}</div>
                                                    <div className={styles.enable_signal_systems_list_soc}>{item.socLabel}</div>
                                                     <Checkbox checked={item.selected} onClick={()=>{
                                                        let tempSystems = [...aliveSystemsData];
                                                        tempSystems[index].selected = !item.selected;
                                                        setSystemsData(tempSystems)
                                                        }} />
                                                </div>
                                            );
                                        })}
                                    </div>

                                </div>
                                <div className={classes.modal_button_row}>
                                    <div className={classes.sig_button} onClick={dismissModal}>
                                        {translation("buttonLabel.cancel")}
                                    </div>
                                    <div className={classes.sig_button} onClick={() => {props.setStateModal(2);}}>
                                        {translation("buttonLabel.next")}
                                    </div>
                                </div>
                            </div>
                        
                        ) : props.stateModal === 2 ? (
                            /* *****************************MODE 2 - OPTIONS FORM FOR THE SIGNAL EVENT******************************/
                            <div>
                                {props.signalNumber === 1 ?
                                    /* *****************************SIGNAL 1 FORM OPTIONS***************************** */
                                    (<div className={classes.modal_body}>
                                        <div className={classes.modal_subheader}>{translation("globalAdjustment.setTimes")}</div>
                                        <div className={classes.sig1_param} onClick={() => { setInfo({ open: true, type: "charge" }); }}>
                                            <div>{translation("globalAdjustment.batteryReadyTime")}</div>
                                            <img alt="" src={infoIcon} className={classes.sig1_icon}/>
                                        </div>
                                        <input  className={classes.sig1_input} type="time" value={sigConfig.BatteryReadyTime}
                                            onChange={(e) => {setSigConfig( {...sigConfig,"BatteryReadyTime" : e.target.value });}}/>

                                        <div className={classes.sig1_param} onClick={() => {setInfo({ open: true, type: "discharge" });}}>
                                            <div>{translation("globalAdjustment.signal1ResetTime")}</div>
                                            <img alt="" src={infoIcon} className={classes.sig1_icon}/>
                                        </div>
                                        <input className={classes.sig1_input} type="time" value={sigConfig.Signal1ResetTime}
                                            onChange={(e) => {setSigConfig( {...sigConfig,"Signal1ResetTime" : e.target.value });}}
                                        />
                                    </div>) :
                                    /* *****************************SIGNAL 2 FORM OPTIONS***************************** */
                                    (<div className={classes.modal2_body}>
                                        <div className={classes.modal2_body_column}>
                                            <div className={[styles.modal2_row, styles.paramText2].join(" ")}>
                                                <div>{translation("globalAdjustment.StartTime")}</div>
                                                <input className={styles.sig2_input} type="time" value={sigConfig.StartTime}
                                                    onChange={(e) => {setSigConfig( {...sigConfig, "StartTime" : e.target.value });}}/>
                                            </div>
                                            <div className={[styles.modal2_row, styles.paramText2].join(" ")}>
                                                <div>{translation("globalAdjustment.percentESS(G/A)")}</div>
                                                <input  className={styles.sig2_input} type="number" min={0} max={100}  step={1}
                                                    value={sigConfig.PercentEssGa}
                                                    onChange={(e) => {setSigConfig( {...sigConfig, "PercentEssGa" : parseFloat(e.target.value) });}}/>
                                            </div>
                                            <div className={[styles.modal2_row, styles.paramText2].join(" ")}>
                                                <div>{translation("globalAdjustment.timeOfRecharge")}</div>
                                                <input className={styles.sig2_input} type="time" value={sigConfig.TimeOfRecharge}
                                                    onChange={(e) => {setSigConfig( {...sigConfig, "TimeOfRecharge" : e.target.value });}}/>
                                            </div>
                                        </div>
                                        <div className={classes.modal2_body_column}>
                                            <div className={[styles.modal2_row, styles.paramText2].join(" ")}>
                                                <div>{translation("globalAdjustment.dischargeDuration")}</div>
                                                <input className={styles.sig2_input} type="number" min={0} step={1}
                                                    value={sigConfig.DischargeDuration}
                                                    onChange={(e) => {setSigConfig( {...sigConfig, "DischargeDuration" : parseInt(e.target.value) });}}/>
                                            </div>
                                            <div className={[styles.modal2_row, styles.paramText2].join(" ")}>
                                                <div style={{ display: "flex", alignItems: "center" }} onClick={() => {setInfo({ open: true, type: "boost" })}}> 
                                                    <div>{translation("globalAdjustment.essBootEnable")} </div>
                                                    <img alt="" src={infoIcon} style={{ height: "40%", marginLeft: "3%" }}/>
                                                </div>
                                                <Switch checked={sigConfig.essBoostEnable}
                                                    onChange={(e) => {setSigConfig( {...sigConfig, "essBoostEnable" : !!e.target.value });}}/> 
                                            </div>
                                            <div className={[styles.modal2_row, styles.paramText2].join(" ")}>
                                                <div className={styles.sig2_label}>{translation("globalAdjustment.rechargePowerLevel")}</div>
                                                <Box sx={{ width: "90%", height: "30px" }}>
                                                    <Select
                                                    sx={{ width: "100%", height: "100%", fontSize: "1vw", fontWeight: 600, color: "#5f6468"}}
                                                    value={sigConfig.RechargePowerLevel}
                                                    onChange={(e) => {setSigConfig( {...sigConfig, "RechargePowerLevel" : parseInt(e.target.value.toString()) });}}> 
                                                        {Object.entries(rechargePowerLevelList).map(([levelValue,levelString] ) => {
                                                            return (<MenuItem key={levelValue} value={levelValue}>{levelString}</MenuItem>);
                                                        })}
                                                    </Select>
                                                </Box>
                                            </div>
                                        </div>
                                    </div>)
                                }
                                <div className={classes.modal_button_row}>
                                    <div className={classes.sig_button} onClick={() => { props.setStateModal(1);}}>
                                        {translation("buttonLabel.back")}
                                    </div>
                                    <div className={classes.sig_button_init} onClick={initiate}>
                                        {translation("buttonLabel.initiate")}
                                    </div>
                                </div>
                            </div>
                        // ) : props.stateModal === 3 ? (
                            /* *****************************MODE 3 - SIGNAL 2 DUPLICATE EVENTS******************************/
                            // <div>
                            //     <div className={styles.modal_subheader}>{translation("globalAdjustment.siteRewriteConfirmMsg")}</div>
                            //     <div className={classes.modal_body}>
                            //         <div className={styles.rewrite_text}>
                            //             {sigDuplicate.current.map((item: any, index: number) => {
                            //                 return (<div key={index}>{item.label}</div>);
                            //             })}
                            //         </div>
                            //     </div>
                            //     <div className={[styles.modal_button_row].join(" ")}>
                            //         <div className={styles.sig_button} onClick={() => {props.setStateModal(2);}}>
                            //             {translation("buttonLabel.back")}
                            //         </div>
                            //         <div className={styles.sig_button_init}
                            //                 onClick={() => {
                            //                     props.setStateModal(5);
                            //                 }}>
                            //             {translation("buttonLabel.confirm")}
                            //         </div>
                            //     </div>
                            // </div>
                        ) : (
                            /* *****************************MODE 5 - CONFIRM SELECTED OPTIONS AND SEND THE EVENTS REQUEST******************************/
                            <div>
                                <div className={styles.modal_subheader}>{translation(`globalAdjustment.sig${props.signalNumber}InitiateConfirmMsg`)}</div>
                                <div className={classes.modal_body}>

                                    {props.signalNumber === 1 ? (
                                        /* *****************************SIGNAL 1 SELECTED OPTIONS***************************** */
                                        <div>
                                            <div className={classes.display_params}>
                                                <div>{translation("globalAdjustment.batteryReadyTime")}</div>
                                                <div className={classes.display_params_value}>{sigConfig.BatteryReadyTime }</div>
                                            </div>
                                            <div className={classes.display_params}>
                                                <div>{translation("globalAdjustment.signal1ResetTime")}</div>
                                                <div className={classes.display_params_value}>{sigConfig.Signal1ResetTime }</div>
                                            </div>
                                        </div>
                                    ) : (
                                        /* *****************************SIGNAL 2 SELECTED OPTIONS***************************** */
                                    <div>
                                        <div className={classes.display_params}>
                                            <div>{translation("globalAdjustment.StartTime")}</div>
                                            <div className={classes.display_params_value}>{sigConfig.StartTime}</div>
                                        </div>
                                        <div className={classes.display_params}>
                                            <div>{translation("globalAdjustment.dischargeDuration")}</div>
                                            <div className={classes.display_params_value}>{sigConfig.DischargeDuration}min</div>
                                        </div>
                                        <div className={classes.display_params}>
                                            <div>{translation("globalAdjustment.percentESS(G/A)")}</div>
                                            <div className={classes.display_params_value}>{sigConfig.PercentEssGa}%</div>
                                        </div>
                                        <div className={classes.display_params}>
                                            <div>{translation("globalAdjustment.essBootEnable")}</div>
                                            <div className={classes.display_params_value}>{sigConfig.essBoostEnable ? "ON" : "OFF"}</div>
                                        </div>
                                        <div className={classes.display_params}>
                                            <div>{translation("globalAdjustment.timeOfRecharge")}</div>
                                            <div className={classes.display_params_value}>{sigConfig.TimeOfRecharge}</div>
                                        </div>
                                        <div className={classes.display_params}>
                                            <div>{translation("globalAdjustment.rechargePowerLevel")}</div>
                                            <div className={classes.display_params_value}>{rechargePowerLevelList[sigConfig.RechargePowerLevel]}</div>
                                        </div>
                                    </div>
                                    )}
                                    <hr />
                                    <div className={styles.sites_label}>{translation(`globalAdjustment.sitesLabel`)}</div>
                                    <div className={styles.sites_list}>
                                        {selectedSystems.map((item: any, index: number) => {
                                            return (
                                            <div className={[classes.modal_row, classes.paramText2, classes.final_sites_signal_event ].join( " ")} key={index}>
                                                {item.label}
                                            </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className={styles.modal_button_row}>
                                    <div className={styles.sig_button} onClick={() => {props.setStateModal(2);}}>
                                        {translation("buttonLabel.back")}
                                    </div>
                                    <div className={styles.sig_button_init} onClick={()=>finalActions(false, props)}>
                                        {translation("buttonLabel.initiateConfirm")}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

            </EmsModal>
        </Fragment>
    )
}

export default AddSignalModal;

export const rechargePowerLevelList : {[key: number]:string }  = {
    1 : "1 - 40kW",
    2 : "2 - 80kW",
    3 : "3 - 120kW",
    4 : "4 - 160kW",
    5 : "5 - 200kW",
}
interface AddSigEventProps {
    signalNumber : number;
    stateModal: Number; // chanmge to show modal
    setStateModal: Function;
    setSigPendings: Function;
    signalEmailAddress?: string;
    aliveSystems : InteractiveSystem[];
    setErrorModal: Function;
    emailEnable: boolean;
  }

export  interface InteractiveSystem extends System{
    label: string;
    selected : boolean;
    soc : any;
    socLabel : string;
  }