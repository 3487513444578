import { Modal, Spacer, Text } from "@nextui-org/react";
import CostProfile from "@pages/pricingConfig/components/costProfile";
import { IoTActionAPI, IoTV2API } from "@services/index";
import { useSnackbar } from "notistack";
import {
  handleError,
  handleSuccess,
  sleepThenUpdate,
} from "@utils/pricingConfig";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "@pages/pricingConfig/components/confirmModal";
import { useState } from "react";
import { setActiveCostProfile } from "@store";
import CustomButton from "@pages/pricingConfig/components/customButton";
import { useSystems } from "@ecamion/sucrose";

function Button({ text, onClick }: any) {
  return (
    <CustomButton fullWidth bold={false} auto onClick={onClick}>
      {text}
    </CustomButton>
  );
}

export default function CostProfileActions({
  visible,
  setVisible,
  costProfile,
}: any) {
  const closeHandler = () => {
    setVisible(false);
  };
  const systemId = useSelector((state: any) => state.system.id);
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState("");
  const [confirmationContent, setConfirmationContent] = useState(<></>);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [onConfirm, setOnConfirm] = useState(() => async () => {});
  const dispatch = useDispatch();
  const systems = useSystems(true);
  const applyToSystems = async (ss: any) => {
    const keys = Array.isArray(ss) ? ss : Object.keys(ss);
    for (let key of keys) {
      const response = await IoTActionAPI.post(
        `${key}/cost-profile`,
        costProfile
      ).catch((error) => {
        handleError(error, enqueueSnackbar);
      });
      if (response) {
        handleSuccess(
          enqueueSnackbar,
          `Successfully applied cost profile to ${key}`
        );
        if (key === `system-${systemId}`) {
          dispatch(setActiveCostProfile(response?.data.payload));
        }
      }
    }
    sleepThenUpdate().then();
  };
  return (
    <>
      {costProfile ? (
        <>
          <ConfirmModal
            title={title}
            content={confirmationContent}
            visible={confirmVisible}
            setVisible={setConfirmVisible}
            onConfirm={onConfirm}
          />
          <Modal
            closeButton
            aria-labelledby="modal-title"
            open={visible}
            onClose={closeHandler}
            width="500px"
          >
            <Modal.Header>
              <Text b id="modal-title" size={20} css={{ color: "#414141" }}>
                {"Latest Version - "} {costProfile.name}
              </Text>
            </Modal.Header>
            <Modal.Body>
              <CostProfile
                costProfile={costProfile}
                showTitle={false}
                padding={0}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                text={`Apply to current system-${systemId}`}
                onClick={() => {
                  IoTActionAPI.post(
                    `system-${systemId}/cost-profile`,
                    costProfile
                  )
                    .then((response) => {
                      handleSuccess(
                        enqueueSnackbar,
                        `Successfully applied cost profile to system-${systemId}`
                      );
                      dispatch(setActiveCostProfile(response.data.payload));
                      setVisible(false);
                      sleepThenUpdate().then();
                    })
                    .catch((error) => {
                      handleError(error, enqueueSnackbar);
                    });
                }}
              />
              <Button
                text={"Apply to all systems"}
                onClick={() => {
                  setTitle(
                    "Are you sure you want to apply this cost profile to following systems?"
                  );
                  setConfirmationContent(
                    <>
                      {Object.values(systems).map((system: any) => (
                        <Text size={18} key={system.id}>
                          {system.id}: {system.siteName}
                        </Text>
                      ))}
                    </>
                  );
                  setOnConfirm(() => async () => {
                    await applyToSystems(systems);
                  });
                  setConfirmVisible(true);
                }}
              />
              <Button
                text={"Update all systems with this profile"}
                onClick={async () => {
                  const systemsWithActiveProfile: any[] = [];
                  for (let key of Object.keys(systems)) {
                    const response = await IoTV2API.get(
                      "active-cost-profile/" + systems[Number(key)].id
                    ).catch((error) => {
                      handleError(error, enqueueSnackbar);
                    });
                    if (response && response.data) {
                      if (response.data.id === costProfile.id) {
                        systemsWithActiveProfile.push(key);
                      }
                    }
                  }
                  if (systemsWithActiveProfile.length === 0) {
                    enqueueSnackbar(
                      "No systems have this cost profile as active",
                      { variant: "warning" }
                    );
                  } else {
                    setTitle(
                      "Are you sure you want to apply this cost profile to following systems?"
                    );
                    setConfirmationContent(
                      <>
                        {systemsWithActiveProfile.map((system: string) => (
                          <Text size={18} key={system}>
                            {system}: {systems[Number(system)].siteName}
                          </Text>
                        ))}
                      </>
                    );
                    setOnConfirm(() => async () => {
                      await applyToSystems(systemsWithActiveProfile);
                    });
                    setConfirmVisible(true);
                  }
                }}
              />
              <Spacer y={0.5} />
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
