import { useState, Fragment, FunctionComponent } from "react";
import styles from "./sideBar.module.scss";
import ContactModal from "./components/contact/";
import SideBarItemEMS from "./components/ems";
import SideBarItemBMS from "./components/bms";
import SideBarItemProfile from "./components/profile";
import SideBarItemDashboard from "./components/dashboard";
import SideBarItemLocation from "./components/location";
import SideBarItemChargingDispensers from "./components/chargingDispenser";
import SideBarItemPricingConfig from "./components/pricingConfig";
import SideBarItemChargingReports from "./components/chargingReports";
import SideBarItemClock from "./components/clock";
import { useSelectedSystem } from "@ecamion/sucrose";

const SideBar: FunctionComponent = ({ children }: any) => {
  const system = useSelectedSystem();
  const isSystemConnected = system?.alive || false;
  const [isContactOpen, setIsContactOpen] = useState(false);

  return (
    <Fragment>
      <ContactModal
        isContactOpen={isContactOpen}
        setIsContactOpen={setIsContactOpen}
        contactCenterInfo={system || {}}
      />
      <div className={styles.SideBarContainer}>
        <div className={styles.sidebar_category_top}>
          <div>
            <b>System time</b>
          </div>
          <SideBarItemClock />
        </div>
        <div className={styles.SideBarList}>
          <SideBarItemProfile />
          <SideBarItemDashboard />
          <SideBarItemEMS connection={isSystemConnected} />
          {isSystemConnected && (
            <>
              <SideBarItemBMS />
              <SideBarItemChargingDispensers />
            </>
          )}
          {isSystemConnected && <SideBarItemPricingConfig />}
          <SideBarItemChargingReports />
          <SideBarItemLocation />
          <div
            className={styles.sidebar_category_bottom}
            onClick={() => {
              setIsContactOpen(true);
            }}
          >
            Contacts
          </div>
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </Fragment>
  );
};

export default SideBar;
