import { SessionSummary,  useSessionSummary } from '@ecamion/sucrose';
import { ObjectAsItem } from '@ecamion/sucrose/dist/util/types';
import { getDateWithFixedHours, getUnixTimestamp } from '@utils/common';
import React, { useState } from 'react'
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import styles from "./historicalSessions.module.scss";
import { useTranslation } from "react-i18next";
const HistoricalSessions = (props :any) => {
  const { t: translation } = useTranslation();
 
  
  const [sessionSummary] = useSessionSummary({
      systemIds: props.systemsIds,
      start: getUnixTimestamp(getDateWithFixedHours(props.startDate,true)),
      end: getUnixTimestamp(getDateWithFixedHours(props.endDate))
  })
  return (
    <div className={styles.historical_container}>
      <div className={styles.historical_header}>
          <div className={styles.historical_header_left}>Sessions Summary</div>
          <div className={styles.historical_header_right}> 
              <div style={{ marginRight: "5%", zIndex: 9999 }}>
                  <DatePicker
                  onChange={(value: any) => {
                      props.setStartDate(value);
                  }}
                  value={props.startDate}
                  clearIcon={null}
                  />
              </div>
              <div style={{ marginRight: "5%", zIndex: 9999 }}>
                  <DatePicker
                  onChange={(value: any) => {
                      props.setEndDate(value);
                  }}
                  value={props.endDate}
                  clearIcon={null}
                  />
              </div>
          </div>
      </div>
      <div className={styles.historical_body}>
        <div className={styles.historical_value_container}>
          <p className={styles.historical_value_title}>
            {`Energy`}:
          </p>
          <p className={styles.historical_value_label}>
            {sessionSummary?.energy}  kWh
          </p>
        </div>
        <div className={styles.historical_value_container}>
          <p className={styles.historical_value_title}>
            {`Charging Revenue`}:
          </p>
          <p className={styles.historical_value_label}>
          $  {sessionSummary?.fees} Raw Currency
          </p>
        </div>
        <div className={styles.historical_value_container}>
          <p className={styles.historical_value_title}>
            {`Sessions`}:
          </p>
          <p className={styles.historical_value_label}>
            {sessionSummary?.sessions} 
          </p>
        </div>
        <div className={styles.historical_value_container}>
          <p className={styles.historical_value_title}>
            {`Mileage`}:
          </p>
          <p className={styles.historical_value_label}>
            {sessionSummary?.mileage} miles
          </p>
        </div>
        <div className={styles.historical_value_container}>
          <p className={styles.historical_value_title}>
            {`CO2 Offset`}:
          </p>
          <p className={styles.historical_value_label}>
            {sessionSummary?.co2Offset} tonnes
          </p>
        </div>
        <div className={styles.historical_value_container}>
          <p className={styles.historical_value_title}>
            {`Avg. Duration`}:
          </p>
          <p className={styles.historical_value_label}>
           {sessionSummary?.avg_duration}
          </p>
        </div>
        <div className={styles.historical_value_container}>
          <p className={styles.historical_value_title}>
            {`Avg. SOC Change`}:
          </p>
          <p className={styles.historical_value_label}>
            {sessionSummary?.avg_soc_change} %
          </p>
        </div>
      </div>
    </div>
  )
}

export default HistoricalSessions;