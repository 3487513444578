import "./i18n";

import { Route, Routes } from "react-router-dom";
import { FunctionComponent } from "react";
import CellTemperature from "@pages/cellTemperature";
import ChargingDispensers from "@pages/chargingDispensers";
import GlobalAdjustment from "@pages/globalAdjustment";
import Landmarkchargehub from "@pages/landmarkChargehub";
import Metering from "@pages/metering";
import { PrivateRoute } from "@ecamion/sucrose";
import ChargingReports from "@pages/chargingReports";
import SiteOverview from "@pages/siteOverview";
import SubSystemStatus from "@pages/subSystemStatus";
import SystemStatus from "@pages/systemStatus";
import TempHistory from "@pages/tempHistory";
import UserProfile from "@pages/userProfile";
import WithSidebar from "@components/sideBar/withSidebar";
import PricingConfig from "@pages/pricingConfig";
import { useSelectedSystem } from "@ecamion/sucrose";
import { configureSucrose } from "@ecamion/sucrose";

(() => {
  const isLocal = process.env.REACT_APP_VITE_IS_LOCAL === "true";
  configureSucrose({
    isLocal: isLocal,
    iotURL: isLocal
      ? `http://${window.location.hostname}:8080`
      : `https://${process.env.REACT_APP_VITE_IOT_HOST}`,
    iotHost: isLocal
      ? `${window.location.hostname}:8080`
      : (process.env.REACT_APP_VITE_IOT_HOST as string),
    signOutHost: process.env.REACT_APP_VITE_OAUTH2_SIGN_OUT_HOSTNAME as string,
    auth0Host: process.env.REACT_APP_VITE_AUTH0_DOMAIN as string,
  });
})();

const App: FunctionComponent = () => {
  const { alive } = useSelectedSystem() || {};

  return (
    <div className="auth" style={{ height: "100%" }}>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Landmarkchargehub />} />
          <Route path="/landmark_chargehub" element={<Landmarkchargehub />} />
          <Route element={<WithSidebar />}>
            {alive ? (
              <>
                <Route path="/user" element={<UserProfile />} />
                <Route path="/site_overview" element={<SiteOverview />} />
                <Route
                  path="/charging_dispensers"
                  element={<ChargingDispensers />}
                />
                <Route path="/system_status" element={<SystemStatus />} />
                <Route path="/temp_history" element={<TempHistory />} />
                <Route path="/subsystem_status" element={<SubSystemStatus />} />
                <Route path="/charging_reports" element={<ChargingReports />} />
                <Route path="/metering" element={<Metering />} />
                <Route path="/cell_temperature" element={<CellTemperature />} />
                <Route
                  path="/global_adjustment"
                  element={<GlobalAdjustment />}
                />
                <Route path="/pricing_config" element={<PricingConfig />} />
              </>
            ) : (
              <>
                <Route path="/user" element={<UserProfile />} />
                <Route
                  path="/landmark_chargehub"
                  element={<Landmarkchargehub />}
                />
                <Route path="/site_overview" element={<SiteOverview />} />
                <Route path="/temp_history" element={<TempHistory />} />
                <Route path="/charging_reports" element={<ChargingReports />} />
                <Route path="/metering" element={<Metering />} />
                <Route path="/cell_temperature" element={<CellTemperature />} />
              </>
            )}
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default App;
