import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

interface SideBarState {
  category: string;
  subcategory: string;
}

const sidebarInitialStates: SideBarState = {
  category: "",
  subcategory: "",
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: sidebarInitialStates,
  reducers: {
    setSideBarInfo: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    }
  },
});

export const { setSideBarInfo } = sidebarSlice.actions;
export const useSideBarInfo = () => useSelector((state: any) => state.sidebar as SideBarState);

export default sidebarSlice.reducer;
