import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Text, Textarea } from "@nextui-org/react";
import CustomButton from "@pages/pricingConfig/components/customButton";

const TextAreaModal: FunctionComponent<TextAreaModalProps> = (props) => {
  const { t: translation } = useTranslation();

  const closeHandler = () => {
    props.setVisible(false);
  };
  return (
    <Modal
      scroll
      closeButton
      aria-labelledby="modal-title"
      open={props.visible}
      onClose={closeHandler}
      width="700px"
    >
      <Modal.Header>
        <Text b id="modal-title" size={20} css={{ color: "#414141" }}>
          {props.title}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: "400px" }}>
          <Textarea
            label={props.label}
            placeholder={props.placeholder}
            helperText={props.helperText}
            bordered
            fullWidth
            minRows={16}
            maxRows={16}
            defaultValue={props.text}
            value={props.text}
            onChange={(e) => props.setText(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          auto
          cbuttonType={"transparent"}
          onClick={() => {
            props.onCancel();
            closeHandler();
          }}
        >
          Cancel
        </CustomButton>

        <CustomButton
          bold={false}
          auto
          onClick={() => {
            props.onConfirm(props.text);
          }}
        >
          {props.confirmText || translation("pricingConfig.confirmText")}
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

interface TextAreaModalProps {
  visible: boolean;
  setVisible: Function;
  title: string;
  label: string;
  placeholder: string;
  helperText: string;
  confirmText?: string;
  onCancel: Function;
  onConfirm: Function;
  text: string;
  setText: Function;
}

export default TextAreaModal;
