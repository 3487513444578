import React, { useState, FunctionComponent, useEffect } from "react";
import styles from "../meterSummary.module.scss";
import "@components/metering/meter.css";
import { useTranslation } from "react-i18next";
import { energyExportPriceState, energyDrawPriceState } from "@config/recoil_state";
import { useRecoilState } from "recoil";

const EnergyPrice: FunctionComponent<EnergyPriceProps> = (props) => {
  const { t: translation } = useTranslation();
  const [energyExportPrice, setEnergyExportPrice] = useRecoilState(energyExportPriceState);
  const [energyDrawPrice, setEnergyDrawPrice] = useRecoilState(energyDrawPriceState);
  const [drawPrice, setDrawPrice] = useState(0);
  const [exportPrice, setExportPrice] = useState(0);
  const [nextProfit, setNetProfit] = useState(0);

  useEffect(() => {
    let updatedDrawPrice = 0;
    let updatedExportPrice = 0;
    if (props.dataDelivered.length > 0) {
      updatedDrawPrice = props.dataDelivered.reduce((pre, current) => current + pre) * energyDrawPrice;
    }
    if (props.dataReceived.length > 0) {
      updatedExportPrice = props.dataReceived.reduce((pre, current) => current + pre) * energyExportPrice;
    }

    if ( (props.dataDelivered.length > 0) && (props.dataReceived.length > 0) ) {
      const updatedNetProfit = updatedExportPrice - updatedDrawPrice;
      setNetProfit(updatedNetProfit);
      setDrawPrice(updatedDrawPrice);
      setExportPrice(updatedExportPrice);
    }
  }, [energyDrawPrice, energyExportPrice, props.dataDelivered, props.dataReceived]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
    >
      <div className={styles.param_itemC}>
        <div
          className={[styles.param_header_con, styles.header1].join(" ")}
          style={{ marginBottom: "2%" }}
        >
          {translation("meterSummary.energyDrawPrice")}
        </div>
        <div className={[styles.inputStyle, styles.param_itemC_con].join(" ")}>
          <input
            type="number"
            style={{ height: "100%", marginLeft: "5%", width: "60%" }}
            min={0}
            step={0.1}
            value={energyDrawPrice}
            onChange={(e) => {
              setEnergyDrawPrice(parseFloat(e.target.value));
              localStorage.setItem("EnergyDrawPrice", e.target.value);
            }}
          />
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#484c4f",
            }}
          >
            $/kWh
          </div>
        </div>
      </div>

      <div className={styles.param_itemC}>
        <div
          className={[styles.param_header_con, styles.header1].join(" ")}
          style={{ marginBottom: "2%" }}
        >
          {translation("meterSummary.energyExportPrice")}
        </div>
        <div className={[styles.inputStyle, styles.param_itemC_con].join(" ")}>
          <input
            type="number"
            style={{ height: "100%", marginLeft: "5%", width: "60%" }}
            min={0}
            step={0.1}
            value={energyExportPrice}
            onChange={(e) => {
              setEnergyExportPrice(parseFloat(e.target.value));
              localStorage.setItem("EnergyExportPrice", e.target.value);
            }}
          />
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#484c4f",
            }}
          >
            $/kWh
          </div>
        </div>
      </div>

      <div className={styles.cost}>
        <div className={styles.param_itemD}>
          <div className={[styles.param_header_con, styles.header1].join(" ")}>
            {`${translation("meterSummary.energyReceivedCost")}:`}
          </div>
          <div className={styles.param_itemD_con}>
            ${(props.dataDelivered.length === 0 ? 0 : drawPrice).toFixed(2)}
          </div>
        </div>
        <div className={styles.param_itemD}>
          <div className={[styles.param_header_con, styles.header1].join(" ")}>
            {`${translation("meterSummary.energyDeliveredCost")}:`}
          </div>
          <div className={styles.param_itemD_con}>
            ${(props.dataReceived.length === 0 ? 0 : exportPrice).toFixed(2)}
          </div>
        </div>
        <div className={styles.param_itemD}>
          <div className={[styles.param_header_con, styles.header1].join(" ")}>
            {`${translation("meterSummary.energyNetProfit")}:`}
          </div>
          <div className={styles.param_itemD_con}>
            ${( (props.dataDelivered.length === 0 && props.dataReceived.length === 0) ? 0 : nextProfit).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

interface EnergyPriceProps {
  dataDelivered: any[];
  dataReceived: any[];
}

export default EnergyPrice;
