import { StringModule } from "@services";
import { FunctionComponent } from "react";
import styles from "./batteryModule.module.scss";

const BatteryModule: FunctionComponent<BatteryModuleProps> = (props) => {
  const voltages = Array(12)
    .fill(0)
    .map(
      (_, index) =>
        props.data[("Cell" + (index + 1) + "Voltage") as keyof StringModule]
    );

  const temps = Array(6)
    .fill(0)
    .map(
      (_, index) => props.data[("Temp" + (index + 1)) as keyof StringModule]
    );

  return (
    <div className={styles.mainCon}>
      <div className={styles.titleCon}>{`Module ${props.moduleID}`}</div>

      <div className={styles.bodyCon}>
        <div className={styles.cellCon}>
          <div className={styles.subtitle}>Cell</div>
          <div
            style={{
              width: "100%",
              height: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              marginTop: "2%",
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, index) => {
              return (
                <div key={index} className={styles.dataRow}>
                  {index + 1}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.voltageCon}>
          <div className={styles.subtitle}>Voltage</div>
          <div
            style={{
              width: "100%",
              display: "flex",
              height: "90%",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            {voltages.map((voltage, index) => {
              return (
                <div key={index} className={styles.dataRow}>
                  {voltage !== undefined ? Number(voltage) : "--"} V
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.tempCon}>
          <div className={styles.subtitle}>Thermistor</div>
          <div className={styles.degreeCol}>
            {temps.map((temp, index) => {
              return (
                <div key={index} className={styles.degree}>
                  {temp !== undefined ? Number(temp) : "--"} {"\u00b0"}C
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

interface BatteryModuleProps {
  moduleID: number;
  data: Partial<StringModule>;
}

export default BatteryModule;
