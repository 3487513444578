import {
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import styles from "./activity.module.scss";
import { useEventModal } from "./event-modal";
import { AlarmType, EventType, SignalType } from "./types";
import { Event } from "./event";
import ActiveButton from "./active-button";
import DatePicker from "./datepicker";

export type ActivityProps = {
  events: {
    active: EventType[];
    historical: EventType[];
  };
  dateRange: {
    start: Date;
    setStart: Dispatch<SetStateAction<Date>>;
    end: Date;
    setEnd: Dispatch<SetStateAction<Date>>;
  };
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
};

export const Activity = ({
  events,
  dateRange,
  active,
  setActive,
}: ActivityProps) => {
  const { setShow: setShowModal, EventModalComponent } = useEventModal();
  const [selectedEvent, setSelectedEvent] = useState<SignalType | AlarmType | null>(null);
  const displayEvents = active ? events.active : events.historical;

  return (
    <div className={styles.container}>
      <EventModalComponent event={selectedEvent} />
      <div className={styles.title}>Events</div>
      <ActiveButton {...{ active, setActive }} />

      {/* No need to display the date at the top anymore since every event displays the date*/}
      <div className={styles.activity_container}>
        <div style={{ width: "100%", height: "100%" }}>
          {!active && <DatePicker {...dateRange} />}
          {displayEvents.map((event, index) => {
            return (
              <Event
                key={index}
                event={event}
                onClick={() => {
                  setSelectedEvent(event);
                  setShowModal(1);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Activity;
