import "bootstrap/dist/css/bootstrap.css";
import React, { FunctionComponent, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import classes from "./navigationBar.module.scss";
import { signOut } from "@constants/index";
import { useNavigate } from "react-router-dom";
import { useUser } from "@ecamion/sucrose";

const NavigationBar = ( props :any) => {
  const options: {[key:string]:string} = {
                    "locations":"Locations", 
                    "historical":"Sessions Summary", 
                    "revenue":"Revenue Share",
                    "stacked":"Sessions by TOD"
                  };
  const navigate = useNavigate();

  const userInfo = useUser();

  return (
    <div className={classes.navbar__container}>
      <Dropdown className={classes.navbar__dropdown}>
        <Dropdown.Toggle variant="secondary" id="dropdown-landmark">{options[props.displayOption]} </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.keys(options).map((key:string) => 
            <Dropdown.Item className={classes.navbar__button} key={key} onClick={() => props.setDisplayOption(key)}>{options[key]}</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className={classes.navbar__dropdown}>
        <Dropdown.Toggle variant="secondary" id="dropdown-landmark">
          <img
            className={classes.userIcon}
            alt={""}
            src={userInfo?.picture}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            className={classes.navbar__button}
            onClick={() => {
              navigate("/user", {
                state: { headbarTitle: "User Profile" },
              });
            }}
          >
            Profile
          </Dropdown.Item>
          <Dropdown.Item className={classes.navbar__button} onClick={signOut}>
            Sign Out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default NavigationBar;
